import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import FormField from '../../components/form-field';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import ChildrenService from '../../app/service/childrenService';
import MemberService from '../../app/service/memberService';
import { messageSuccess, messageError } from '../../components/toastr';
import { format, parse } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import SelectMenu from "../../components/selectMenu";
import DatePicker from 'react-datepicker';
import { AuthContext } from '../../security/AuthContext';

function ChildrenRegister() {
    const navigate = useNavigate();
    const childrenEdit = JSON.parse(localStorage.getItem('children-edit'));
    const childrenService = useMemo(() => new ChildrenService(), []);
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const [visible, setVisible] = useState(false);

    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [dateBorn, setDateBorn] = useState(null);
    const [formattedDateBorn, setFormattedDateBorn] = useState(null);
    const [member, setMember] = useState('');
    const [memberList, setMemberList] = useState([]);
    const [email, setEmail] = useState('');
    const [phoneCell, setPhoneCell] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');

    const selectOption = { label: 'Selecione', value: '0' };

    const { user, isAuthenticated } = useContext(AuthContext);

    const validateEmail = (inputEmail) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    const handleNameChange = (inputName) => {
        setName(inputName);
        setNameError('');
    };

    const handleEmailChange = (inputEmail) => {
        setEmail(inputEmail);

        if (!validateEmail(inputEmail)) {
            setEmailError('Digite um email válido');
        } else {
            setEmail(inputEmail);
            setEmailError('');
        }
        if (inputEmail === '') {
            setEmail(inputEmail);
            setEmailError('');
        }
    };

    const handleDateBornChange = (date) => {
        if (date && date instanceof Date && !isNaN(date)) {
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setDateBorn(date);
            setFormattedDateBorn(formattedDate);
        } else {
            setDateBorn(null);
            setFormattedDateBorn(null);
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, ''); // Remove qualquer caracter não numérico
        const match = cleaned.match(/^(\d{2})(\d{1,5})?(\d{0,4})$/);
        if (match) {
            const part1 = match[1] ? `(${match[1]})` : '';
            const part2 = match[2] ? ` ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`;
        }
        return phoneNumber; // Retorna o original se não houver correspondência
    };

    const handlePhoneCellChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneCell(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };

    const register = async () => {
        if (name === '') {
            setNameError('Digite um nome válido');
            return;
        }

        if (emailError !== '') {
            messageError("Email inválido");
            return;
        }

        setNameError('');
        setEmailError('');

        if (childrenEdit) {
            await childrenService.update({
                id: id,
                email: email,
                name: name,
                phoneCell: phoneCell,
                dtBorn: formattedDateBorn,
                member: member
            }).then(response => {
                messageSuccess('Sobrinho atualizado com sucesso.');
                navigate('/children-list');
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await childrenService.new({
                name: name,
                email: email,
                phoneCell: phoneCell,
                dtBorn: formattedDateBorn,
                member: member
            }).then(response => {
                messageSuccess('Sobrinho cadastrado com sucesso.');
                navigate('/children-list');
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");
            if (isAuthenticated) {
                const accessLevel = await userService.hasAccess(user.id);
                setVisible(user.role === 1 || accessLevel.data === 1);

                if (childrenEdit) {
                    const response = await childrenService.getById(childrenEdit);
                    const childrenData = response.data;

                    setId(childrenData.id);
                    setName(childrenData.name);
                    setEmail(childrenData.email);
                    setPhoneCell(childrenData.phoneCell)
                    setMember(childrenData.member);

                    if (childrenData.dtBorn) {
                        setFormattedDateBorn(childrenData.dtBorn);
                        setDateBorn(new Date(childrenData.dtBorn[0], childrenData.dtBorn[1] - 1, childrenData.dtBorn[2]))
                    }
                    if ((user.role !== 1 && accessLevel.data != 1) && user.member != childrenData.member) {
                        messageError("Você não possui acesso.");
                        navigate('/children-list');
                        return;
                    }
                }

            } else {
                navigate('/login');
            }



            const response = await memberService.getAll();
            const listMembers = [
                selectOption,
                ...response.data.map(item => ({
                    label: item.name,
                    value: item.id
                })).sort((a, b) => a.label.localeCompare(b.label))
            ];
            setMemberList(listMembers);
        };

        fetchData();
        //localStorage.removeItem("children-edit")

    }, [childrenEdit, memberService, navigate, userService, childrenService]);

    return (
        <div>
            <Container>
                <Card title="Cadastro de novo sobrinho">
                    <Container>
                        <fieldset>
                            <FormGroup label="Irmão: *" htmlFor="selectMember">
                                <br />
                                <SelectMenu
                                    className="form-control"
                                    list={memberList}
                                    value={member}
                                    onChange={(e) => setMember(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                    disabled={!visible}
                                />
                            </FormGroup>
                            <br />
                            <FormGroup label="Nome do(a) sobrinho(a): *" htmlFor="inputName" error={nameError}>
                                <input
                                    className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                    type="text"
                                    id="inputName"
                                    name="name"
                                    value={name}
                                    placeholder="Digite o nome"
                                    onChange={(e) => handleNameChange(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                />
                                {nameError && (
                                    <div className="invalid-feedback">
                                        {nameError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormGroup label="Data de Nascimento: " htmlFor="inputDateBorn">
                                <br />
                                <DatePicker
                                    selected={dateBorn}
                                    onChange={handleDateBornChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    locale="pt-BR"
                                    placeholderText="DD/MM/AAAA"
                                    value={dateBorn}
                                />
                            </FormGroup>
                            <br />
                            <FormGroup label="Email: " htmlFor="inputEmail" error={emailError}>
                                <input
                                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                    type="email"
                                    id="inputEmail"
                                    name="email"
                                    value={email}
                                    placeholder="Digite o email"
                                    onChange={(e) => handleEmailChange(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                />
                                {emailError && (
                                    <div className="invalid-feedback">
                                        {emailError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                <label htmlFor="inputPhoneCell">Telefone Celular:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: '22%' }}
                                    id="inputPhoneCell"
                                    name="phoneCell"
                                    placeholder="Digite o Celular"
                                    value={phoneCell}
                                    onChange={handlePhoneCellChange}
                                />
                            </FormField>
                        </fieldset>
                    </Container>
                </Card>

                <button onClick={register} className="btn btn-success">
                    Cadastrar
                </button>
                {' '}
                <button onClick={e => navigate('/children-list')} className="btn btn-danger">
                    Cancelar
                </button>
            </Container>
        </div>
    );
}

export default ChildrenRegister;
