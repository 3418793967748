import React, { useEffect, forwardRef } from 'react';
import MemberService from '../app/service/memberService';
import PRMTSService from '../app/service/prmtsService';
// Remova a importação se estiver usando a pasta public
// import marcaDagua from '../assets/marca_dagua_1.png'; 

const AttendanceCertificate = forwardRef(({ event, visitor, onRenderComplete }, ref) => {
    const memberService = new MemberService();
    const prmtsService = new PRMTSService();
    useEffect(() => {
        const generateCertificateImage = async () => {

            try {

                const responseDaytime = await prmtsService.getByType('DAYTIME-SESSION');

                const sessionDayTime = responseDaytime.data.description;


                const canvas = ref.current;
                if (!canvas) {
                    console.error('Canvas não encontrado!');
                    return;
                }

                const context = canvas.getContext('2d');
                if (!context) {
                    console.error('Contexto do canvas não encontrado!');
                    return;
                }

                const memberRole1 = await memberService.getMemberByLodgeRole(1);
                const memberRole7 = await memberService.getMemberByLodgeRole(7);

                // Definir o tamanho do canvas
                const canvasWidth = 595;
                const canvasHeight = 842;
                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                // Carregar fontes personalizadas
                const loadFont = async (fontName, url) => {
                    try {
                        const font = new FontFace(fontName, `url(${url})`);
                        await font.load();
                        document.fonts.add(font);
                    } catch (error) {
                        console.error(`Erro ao carregar a fonte ${fontName}:`, error);
                    }
                };

                await Promise.all([
                    loadFont('GreatVibes', '/fonts/GreatVibes-Regular.ttf'),
                    loadFont('TimesNewRoman', '/fonts/times-new-roman.ttf')
                ]);

                // Aguardar fontes serem carregadas
                await document.fonts.ready;

                // Desenhar o fundo branco
                context.fillStyle = '#FFFFFF';
                context.fillRect(0, 0, canvasWidth, canvasHeight);

                // Carregar a marca d'água
                const watermark = new Image();
                // Se estiver usando a pasta public, use a URL relativa
                const watermarkSrc = `${process.env.PUBLIC_URL}/assets/marca_dagua_1.png`;
                watermark.src = watermarkSrc;

                // Opcional: Definir CORS se necessário
                // watermark.crossOrigin = 'Anonymous';

                // Esperar a imagem carregar antes de desenhar
                await new Promise((resolve, reject) => {
                    watermark.onload = () => {
                        resolve();
                    };
                    watermark.onerror = () => {
                        console.error('Erro ao carregar a marca d\'água.');
                        reject(new Error('Erro ao carregar a marca d\'água.'));
                    };
                });

                // Calcular as dimensões para preencher a folha
                const watermarkScale = 0.9; // 90% da largura do canvas
                const watermarkWidth = canvasWidth * watermarkScale;
                const watermarkHeight = (watermark.height * watermarkWidth) / watermark.width;

                // Centralizar a marca d'água
                const xPosition = (canvasWidth - watermarkWidth) / 2;
                const yPositionWatermark = (canvasHeight - watermarkHeight) / 2;

                // Definir a opacidade da marca d'água
                context.globalAlpha = 1.0; // 10% de opacidade

                // Desenhar a marca d'água no canvas
                context.drawImage(watermark, xPosition, yPositionWatermark, watermarkWidth, watermarkHeight);

                // Resetar a opacidade para futuros desenhos
                context.globalAlpha = 1.0;

                // Agora, desenhar os textos sobre a marca d'água

                context.fillStyle = '#000000';
                context.textAlign = 'center';

                // Título
                context.font = '44px GreatVibes';
                context.fillText('Loja Maçônica', canvasWidth / 2, 80);
                context.fillText('Professor Hermínio Blackman', canvasWidth / 2, 130);
                context.fillText('nº 1761', canvasWidth / 2, 180);

                // Subtítulo
                context.font = '18px TimesNewRoman';
                context.fillText('FEDERADA AO GOB e JURISDICIONADA AO GOB-ES', canvasWidth / 2, 220);

                context.fillText(`R.E.A.A. - Reuniões às ${sessionDayTime}`, canvasWidth / 2, 240);

                context.fillText('Rua Presidente Lima, 1010, Centro, Vila Velha/ES. CEP 29.100-330', canvasWidth / 2, 260);

                // Título do Certificado
                context.font = '32px TimesNewRoman';
                context.fillText('CERTIFICADO DE PRESENÇA', canvasWidth / 2, 350);

                // Corpo do certificado
                context.font = '14px TimesNewRoman';
                context.textAlign = 'left';
                const leftMargin = 40;
                const rightMargin = 30;
                const maxTextWidth = canvasWidth - leftMargin - rightMargin;
                let yPosition = 420;
                const lineHeight = 24; // Aumentar o espaçamento entre linhas para melhor legibilidade

                // Função para dividir o texto em linhas com base na largura máxima
                const wrapText = (text, maxWidth) => {
                    const lines = [];
                    const paragraphs = text.split('\n');

                    paragraphs.forEach(paragraph => {
                        const words = paragraph.split(' ');
                        let currentLine = '';

                        words.forEach(word => {
                            const testLine = currentLine ? `${currentLine} ${word}` : word;
                            const metrics = context.measureText(testLine);
                            const testWidth = metrics.width;

                            if (testWidth > maxWidth && currentLine) {
                                lines.push(currentLine);
                                currentLine = word;
                            } else {
                                currentLine = testLine;
                            }
                        });

                        if (currentLine) {
                            lines.push(currentLine);
                        }

                        // Adiciona um espaço extra após cada parágrafo
                        lines.push('');
                    });

                    return lines;
                };

                const dtNow = new Date();
                const meses = [
                    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
                    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
                ];

                const dia = dtNow.getDate();
                const mesAtualEscrito = meses[dtNow.getMonth()];
                const ano = dtNow.getFullYear();
                const dataFormatada = `${dia} de ${mesAtualEscrito} de ${ano}`;

                // Unificar o texto do certificado em uma única string com quebras de linha
                const mesEscrito = meses[event.dtStart[1] - 1];
                const certificateText = `
                Certificamos que o Ir.∴ ${visitor.name} - CIM: ${visitor.cim} do quadro da Loj.∴ ${visitor.lodge}, do Or.∴ de ${visitor.lodgeState}, concedeu-nos a honra de sua visita na ${event.description} realizada no dia ${event.dtStart[2]} de ${mesEscrito} de ${event.dtStart[0]}. Contribuindo para estreitar os laços de fraternidade entre os maçons.
                Or.∴ de Vila Velha/ES, ${dataFormatada}.
                V.∴M.∴ ${memberRole1.data.name}  
                Chanc.∴ ${memberRole7.data.name}`;

                // Dividir o texto em linhas com base na largura máxima
                const lines = wrapText(certificateText, maxTextWidth);

                // Desenhar cada linha no canvas
                lines.forEach(line => {
                    if (line.trim() === '') {
                        yPosition += lineHeight; // Espaço extra para linhas vazias (quebra de parágrafo)
                    } else {
                        context.fillText(line, leftMargin, yPosition);
                        yPosition += lineHeight;
                    }
                });

                // Texto final
                context.textAlign = 'center';
                context.fillText('EMITIDO DIGITALMENTE', canvasWidth / 2, yPosition + 50);

                // Chamar a função de callback após gerar o certificado
                if (onRenderComplete) {
                    onRenderComplete();
                }

            } catch (error) {
                console.error('Erro ao gerar a imagem do certificado:', error);
            }
        };

        generateCertificateImage();
    }, [event, visitor, ref, onRenderComplete]);

    return <canvas ref={ref} style={{ display: 'none' }} />;
});

export default AttendanceCertificate;
