import React, { useEffect, useState, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import { useNavigate } from 'react-router-dom';
import { messageError, messageSuccess } from '../../components/toastr';
import { AuthContext } from '../../security/AuthContext';
import Loading from '../../components/loading';

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { isAuthenticated, login, loading } = useContext(AuthContext);

    const handleLogin = async () => {
        try {
            await login(email, password);
            messageSuccess("Usuário logado com sucesso.");
            navigate('/home');
        } catch (error) {
            messageError('Erro ao realizar login. Verifique suas credenciais.');
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/home');
        }
    }, [isAuthenticated, navigate]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {loading && <Loading />}
            <Card title="Login">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bs-component" style={{ padding: '20px' }}>
                            <fieldset>
                                <FormGroup label="Email: *" htmlFor="exampleInputEmail1">
                                    <input
                                        value={email}
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Digite o Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{ padding: '10px 12px', height: '45px', width: '400px' }}
                                    />
                                </FormGroup>
                                <br />
                                <FormGroup label="Senha: *" htmlFor="exampleInputPassword1">
                                    <input
                                        value={password}
                                        type="password"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Digite a Senha"
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{ padding: '10px 12px', height: '45px', width: '400px' }}
                                    />
                                </FormGroup>
                                <br />
                                <button onClick={handleLogin} className="btn btn-success">
                                    Entrar
                                </button>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Login;
