import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button } from 'primereact/button';
import UserService from "../../app/service/userService.js";
import { useNavigate } from 'react-router-dom';
import MemberService from "../../app/service/memberService.js";
import { messageError } from "../../components/toastr";
import { AuthContext } from '../../security/AuthContext';

const calculateAge = (birthDateArray) => {
    if (!birthDateArray || birthDateArray.length !== 3) {
        return null;
    }

    const today = new Date();
    const birthDateObj = new Date(birthDateArray[0], birthDateArray[1] - 1, birthDateArray[2]);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
};

const formatWithLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
};

const formatDate = (birthDateArray) => {
    if (!birthDateArray || birthDateArray.length !== 3) {
        return "Data de nascimento não disponível";
    }

    const [ano, mes, dia] = birthDateArray;
    const formattedDia = formatWithLeadingZero(dia);
    const formattedMes = formatWithLeadingZero(mes);

    return `${formattedDia}/${formattedMes}/${ano}`;
};

const ChildrenDT = (props) => {
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const [rows, setRows] = useState([]);  // State para armazenar os rows
    const [visible, setVisible] = useState(false);
    const { user, isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {

                await processChildren(props.children);

                if (isAuthenticated) {
                    const accessLevel = await userService.hasAccess(user.id);
                    setVisible(user.role === 1 || accessLevel.data === 1);
                }

            } catch (error) {
                if (error.response && error.response.status === 400) {
                    messageError('Requisição inválida. Por favor, efetue o login.');
                } else {
                    messageError('Erro ao buscar informações.');
                }
            }
        };

        fetchData();
    }, [props.children, userService, navigate]);

    async function processChildren(children) {
        const childDetails = await Promise.all(children.map(async (child) => {
            const response = await memberService.getById(child.member);
            return {
                ...child,
                memberDetails: response.data,
            };
        }));

        const newRows = childDetails.map((child, index) => {
            const age = calculateAge(child.dtBorn);
            return (
                <tr key={child.id} style={{ maxWidth: '200px' }}>
                    <td style={{ padding: '5px', width: 'auto' }}>{index + 1}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{child.name}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{child.email}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{age > 18 ? child.phoneCell : ''}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{formatDate(child.dtBorn)}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{child.memberDetails.name}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>
                        {(visible || user.member === child.member) && (
                            <Button
                                icon="pi pi-pencil"
                                rounded outlined severity="success"
                                aria-label="Search"
                                className="small-button btn btn-info"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.editChildren(child.id)}
                            />
                        )}
                        {' '}
                        {(visible) && (
                            <Button
                                icon="pi pi-times"
                                rounded outlined severity="danger"
                                aria-label="Cancel"
                                className="small-button btn btn-danger"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.deleteChildren(child.id)}
                            />

                        )}
                    </td>
                </tr>
            );
        });

        setRows(newRows);
    }

    return (
        <>
            <div style={{ maxWidth: 'auto' }}>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th style={{ width: 'auto' }}>#</th>
                            <th style={{ width: 'auto' }}>Nome</th>
                            <th style={{ width: 'auto' }}>Email</th>
                            <th style={{ width: 'auto' }}>Celular</th>
                            <th style={{ width: 'auto' }}>Data de Nascimento / Idade</th>
                            <th style={{ width: 'auto' }}>Filho(a) de</th>
                            {visible && (
                                <>
                                    <th style={{ width: 'auto' }}>Ações</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
                <div className="mt-4">
                    Total de Sobrinhos: {props.children.length}
                </div>
            </div>
        </>
    );
};

export default ChildrenDT;
