// VisitorAttendanceDT.js
import React from 'react';

const VisitorAttendanceDT = ({ visitors, onSelectVisitor, selectedVisitors }) => {
    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Presença</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Nome</th>
                    <th scope="col" style={{ width: '10%', textAlign: 'center' }}>CIM</th>
                    <th scope="col" style={{ width: '10%', textAlign: 'center' }}>Grau</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Loja</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Ordem</th>
                </tr>
            </thead>
            <tbody>
                {visitors.map((visitor) => (
                    <tr key={visitor.id}>
                        <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                            <input
                                type="checkbox"
                                style={{ transform: 'scale(1.6)' }}
                                checked={selectedVisitors.includes(visitor.id)}
                                onChange={() => onSelectVisitor(visitor.id)}
                            />
                        </td>
                        <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>{visitor.name}</td>
                        <td style={{ width: '10%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>{visitor.cim}</td>
                        <td style={{ width: '10%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>{visitor.lodgeDegree}</td>
                        <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>{visitor.lodge}</td>
                        <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>{visitor.lodgeState}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default VisitorAttendanceDT;
