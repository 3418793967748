import React, { useState, useEffect, useMemo, useContext } from "react";
import MemberService from "../../app/service/memberService";
import SpouseService from "../../app/service/spouseService";
import UserService from "../../app/service/userService.js";
import { messageSuccess, messageError } from "../../components/toastr";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import Container from "../../components/container";
import SelectMenu from "../../components/selectMenu";
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import SpouseDT from "./spouseDT.js";
import { AuthContext } from '../../security/AuthContext';

const SpouseList = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const memberService = useMemo(() => new MemberService(), []);
    const userService = useMemo(() => new UserService(), []);
    const spouseService = useMemo(() => new SpouseService(), []);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [member, setMember] = useState('');
    const [memberList, setMemberList] = useState([]);
    const mountList = (data) => {
        // Add the default option "SELECIONE" with value 0
        const defaultOption = { label: 'Selecione', value: '' };
        return [defaultOption, ...data.map(item => ({ label: item.description, value: item.id }))];
    }
    const handleRegisterNewSpouse = () => {
        navigate('/spouse-register');
        return;
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleMemberChange = (event) => {
        setMember(event.target.value);
    };

    const handleSearch = async () => {
        const spouseFilter = {
            name: name,
            member: member
        };

        try {
            const response = await spouseService.findSpouses(spouseFilter);
            if (Array.isArray(response.data) && response.data.length > 0) {

                // Ordenar os usuários de forma ascendente pelo nome
                const sortedSpouses = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setSearchResults(sortedSpouses);
            } else {
                setSearchResults([]);
                messageError("Nenhum resultado encontrado.");
            }


        } catch (error) {
            messageError(`Erro ao realizar a busca. <br /> ${error.response.data}`);
        }

    };

    const handleClear = () => {
        setName('');
        setMember('');
    }

    const deleteSpouse = async (id) => {
        try {
            const response = await spouseService.deleteSpouse(id);
            if (response.status === 200) { // Supondo que o serviço retorne um status HTTP 200 em caso de sucesso
                messageSuccess("Cunhada deletada com sucesso!");
            } else {
                messageError("Cunhada não encontrada."); // Mudança para uma mensagem mais apropriada
            }
        } catch (error) {
            if (error.response) {
                // Assume-se que a mensagem de erro seja útil e derivada do servidor
                messageError(`Erro ao deletar cunhada`);
            } else {
                messageError("Erro ao conectar com o serviço de cunhadas.");
            }
        }
    }

    const editSpouse = async (id) => {
        localStorage.setItem("spouse-edit", id)
        navigate("/spouse-register")
    }


    const confirmDelete = (id) => {
        setSelectedUserId(id);
        setVisibleModal(true);
    };

    const handleConfirmation = (confirmed) => {
        setVisibleModal(false);

        if (confirmed) {
            deleteSpouse(selectedUserId);
        } else {
            setSelectedUserId(null);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                localStorage.removeItem('spouse-edit');
                //Verificar se o usuário está logado
                if (isAuthenticated) {
                    const accessLevel = await userService.hasAccess(user.id);
                    setVisible(user.role === 1 || accessLevel.data === 1);
                } else {
                    navigate('/login');
                }

                handleSearch();
                //Lista todos os membros
                const response = await memberService.getAll();
                const selectOption = { label: 'Selecione', value: '' };
                const listMembers = [
                    selectOption,
                    ...response.data.map(item => ({
                        label: item.name,
                        value: item.id
                    })).sort((a, b) => a.label.localeCompare(b.label))
                ];
                setMemberList(listMembers);

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []); // Run once when the component mounts


    return (
        <Container>
            <Card title="Consultar Cunhadas" >
                <div className="row">
                    <div className="col-lg-3">
                        <FormGroup label="Nome: " htmlFor="inputName">
                            <input
                                className="form-control"
                                type="text"
                                id="exampleInputName1"
                                name="name"
                                value={name}
                                placeholder="Digite o Nome"
                                onChange={handleNameChange}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-3">
                        <FormGroup label="Irmão: " htmlFor="selectMember">
                            <SelectMenu
                                className="form-control"
                                list={memberList}
                                value={member}
                                onChange={handleMemberChange}
                            />
                        </FormGroup>
                    </div>
                </div>

                <br />
                <button className="btn btn-success" onClick={handleSearch}>Buscar</button>
                {' '}
                <button className="btn btn-danger" onClick={handleClear}>Limpar</button>
                {' '}
                {visible && (
                    <>
                        <button className="btn btn-info" onClick={handleRegisterNewSpouse}>Cadastrar nova cunhada</button>
                    </>
                )}
                <br /><br />
                <div className="row">
                    <div className="spouseDt col-lg-12">
                        <SpouseDT
                            spouses={searchResults}
                            editSpouse={editSpouse}
                            deleteSpouse={confirmDelete}
                        />
                    </div>
                </div>
                <Dialog
                    header="Confirmação"
                    visible={visibleModal}
                    style={{ width: '25vw' }}
                    onHide={() => handleConfirmation(false)}
                    modal={true}
                >
                    <p className="m-0">Deseja excluir o usuário?</p>
                    <br />
                    <div className="p-d-flex p-jc-end">
                        <button
                            className="btn btn-danger p-button-text"
                            onClick={() => handleConfirmation(true)}
                        >
                            Deletar
                        </button>
                        {' '}
                        <button
                            className="btn btn-secondary p-button-text"
                            onClick={() => handleConfirmation(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </Dialog>

            </Card>
        </Container>
    );
};

export default SpouseList;
