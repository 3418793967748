import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../security/PrivateRoute';
import Login from "../views/login/login";
import Home from "../views/home";
import UserList from "../views/user/user-list";
import UserRegister from "../views/user/user-register";
import Unauthorized from "../security/Unauthorized";
import EventCalendar from "../views/calendar/calendar";
import RegisterEvent from "../views/calendar/event-register";
import MemberList from "../views/member/member-list";
import MemberRegister from "../views/member/member-register";
import SpouseRegister from "../views/spouse/spouse-register";
import ChildrenRegister from "../views/children/children-register";
import ChildrenList from "../views/children/children-list";
import AttendanceList from "../views/attendance/attendance-list";
import BoardMembers from "../views/board/board-members";
import Report from "../views/report/report";
import Activities from "../views/activities/activities";
import SpouseList from "../views/spouse/spouse-list";
import EventTypeRegister from "../views/eventType/eventType-register";
import Config from "../views/config/config";
import LoginAttendance from '../views/login/loginAttendance';
import EditUser from "../views/user/user-edit";
import VisitorList from "../views/visitor/visitor-list";
import VisitorRegister from '../views/visitor/visitor-register';


function AppRoutes() {
    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            {/* <Route path="/unauthorized" element={<Unauthorized />} /> */} {/* Create this component if needed */}

            {/* Private Routes */}
            <Route path="" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/loginAttendance" element={<LoginAttendance />} />

            <Route
                path="/user-list"
                element={
                    <PrivateRoute roles={[1]}>
                        <UserList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/user-register"
                element={
                    <PrivateRoute roles={[1]}>
                        <UserRegister />
                    </PrivateRoute>
                }
            />
            <Route
                path="/user-list"
                element={
                    <PrivateRoute roles={[1]}>
                        <UserList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/user-edit"
                element={
                    <PrivateRoute>
                        <EditUser />
                    </PrivateRoute>
                }
            />
            <Route
                path="/calendar"
                element={
                    <PrivateRoute>
                        <EventCalendar />
                    </PrivateRoute>
                }
            />
            <Route
                path="/event-register"
                element={
                    <PrivateRoute>
                        <RegisterEvent />
                    </PrivateRoute>
                }
            />
            <Route
                path="/member-list"
                element={
                    <PrivateRoute>
                        <MemberList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/member-register"
                element={
                    <PrivateRoute>
                        <MemberRegister />
                    </PrivateRoute>
                }
            />
            <Route
                path="/spouse-list"
                element={
                    <PrivateRoute>
                        <SpouseList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/spouse-register"
                element={
                    <PrivateRoute>
                        <SpouseRegister />
                    </PrivateRoute>
                }
            />
            <Route
                path="/children-list"
                element={
                    <PrivateRoute>
                        <ChildrenList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/children-register"
                element={
                    <PrivateRoute>
                        <ChildrenRegister />
                    </PrivateRoute>
                }
            />
            <Route
                path="/attendance-list"
                element={
                    <PrivateRoute>
                        <AttendanceList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/board-members"
                element={
                    <PrivateRoute>
                        <BoardMembers />
                    </PrivateRoute>
                }
            />
            <Route
                path="/report"
                element={
                    <PrivateRoute>
                        <Report />
                    </PrivateRoute>
                }
            />
            <Route
                path="/eventtype-register"
                element={
                    <PrivateRoute>
                        <EventTypeRegister />
                    </PrivateRoute>
                }
            />
            <Route
                path="/config"
                element={
                    <PrivateRoute>
                        <Config />
                    </PrivateRoute>
                }
            />
            <Route
                path="/visitor-list"
                element={
                    <PrivateRoute>
                        <VisitorList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/visitor-register"
                element={
                    <PrivateRoute>
                        <VisitorRegister />
                    </PrivateRoute>
                }
            />

            <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
    );
}

export default AppRoutes;
