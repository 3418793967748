import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button } from 'primereact/button';
import UserService from "../../app/service/userService.js";
import { useNavigate } from 'react-router-dom';
import { messageError } from "../../components/toastr";
import { AuthContext } from '../../security/AuthContext';

const VisitorDT = (props) => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const [rows, setRows] = useState([]);  // State para armazenar os rows
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Verificar se o usuário está logado
                if (isAuthenticated) {
                    const accessLevel = await userService.hasAccess(user.id);
                    setVisible(user.role === 1 || accessLevel.data === 1);
                    await processVisitors(props.visitors);
                } else {
                    navigate('/login');
                }

            } catch (error) {
                if (error.response && error.response.status === 400) {
                    console.error(error.response.data);
                }
            }
        };

        fetchData();
    }, [props.visitors, userService, navigate]);

    async function processVisitors(visitors) {
        const visitorsDetails = await Promise.all(visitors.map(async (visitor) => {
            return {
                ...visitor,
            };
        }));

        const newRows = visitorsDetails.map((visitor, index) => {
            return (
                <tr key={visitor.id} style={{ height: '30px' }}>
                    <td style={{ textAlign: 'center', width: 'auto' }}>{index + 1}</td>
                    <td style={{ textAlign: 'center', width: 'auto' }}>{visitor.cim}</td>
                    <td style={{ textAlign: 'left', width: 'auto' }}>{visitor.name}</td>
                    <td style={{ textAlign: 'center', width: 'auto' }}>{visitor.lodgeDegree}</td>
                    {/*<td style={{ textAlign: 'left',  width: 'auto' }}>{visitor.email}</td>*/}
                    <td style={{ textAlign: 'center', width: 'auto' }}>{visitor.phoneCell}</td>
                    <td style={{ textAlign: 'center', width: 'auto' }}>{visitor.lodge}</td>
                    <td style={{ textAlign: 'center', width: 'auto' }}>{visitor.lodgePotency}</td>
                    <td style={{ textAlign: 'center', width: 'auto' }}>{visitor.lodgeState}</td>
                    <td style={{ textAlign: 'center', width: 'auto' }}>
                        {' '}
                        {(visible) && (
                            <>
                                <Button
                                    icon="pi pi-file-pdf"
                                    rounded outlined severity="success"
                                    aria-label="Edit"
                                    className="small-button btn btn-primary"
                                    style={{ fontSize: '12px', padding: '3px 5px' }}
                                    onClick={e => props.infoVisitor(visitor.id)}
                                />
                                <Button
                                    icon="pi pi-pencil"
                                    rounded outlined severity="success"
                                    aria-label="Search"
                                    className="small-button btn btn-info"
                                    style={{ fontSize: '12px', padding: '3px 5px' }}
                                    onClick={e => props.editVisitor(visitor.id)}
                                />
                                <Button
                                    icon="pi pi-times"
                                    rounded outlined severity="danger"
                                    aria-label="Cancel"
                                    className="small-button btn btn-danger"
                                    style={{ fontSize: '12px', padding: '3px 5px' }}
                                    onClick={e => props.deleteVisitor(visitor.id)}
                                />
                            </>
                        )}

                    </td>
                </tr>
            );
        });

        setRows(newRows);
    }

    return (
        <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', width: 'auto' }}>#</th>
                        <th style={{ textAlign: 'center', width: 'auto' }}>CIM</th>
                        <th style={{ textAlign: 'left', width: 'auto' }}>Nome</th>
                        <th style={{ textAlign: 'left', width: 'auto' }}>Grau</th>
                        {/*<th style={{ textAlign: 'left', width: 'auto', paddingLeft: '2vw' }}>Email</th>*/}
                        <th style={{ textAlign: 'center', width: 'auto' }}>Celular</th>
                        <th style={{ textAlign: 'center', width: 'auto' }}>Loja</th>
                        <th style={{ textAlign: 'center', width: 'auto' }}>Potência</th>
                        <th style={{ textAlign: 'center', width: 'auto' }}>Oriente</th>
                        {visible && (
                            <>
                                <th style={{ textAlign: 'center', width: 'auto' }}>Ações</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <div className="mt-4">
                Total de Visitantes: {props.visitors.length}
            </div>
        </>
    );
};

export default VisitorDT;
