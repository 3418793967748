import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import MemberService from '../../app/service/memberService';
import LodgeRoleService from '../../app/service/lodgeRoleService';
import LodgePartyService from '../../app/service/lodgePartyService';
import PRMTSService from "../../app/service/prmtsService";
import { messageError, messageSuccess } from '../../components/toastr';
import { useNavigate } from 'react-router-dom';
import SelectMenu from '../../components/selectMenu';
import { Button } from 'primereact/button';
import { Modal, Form } from 'react-bootstrap';
import { AuthContext } from "../../security/AuthContext";

const BoardMembers = () => {
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const lodgeRoleService = useMemo(() => new LodgeRoleService(), []);
    const lodgePartyService = useMemo(() => new LodgePartyService(), []);
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const [listMembers, setListMembers] = useState([]);
    const [listMembersParty, setListMembersParty] = useState([]);
    const [rolesWithMembers, setRolesWithMembers] = useState([]);
    const [rolesWithMembers2, setRolesWithMembers2] = useState([]);
    const [partiesWithMembers, setPartiesWithMembers] = useState([]);
    const [resetVisible, setResetVisible] = useState(false);
    const [yearDescription, setYearDescription] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalParty, setShowModalParty] = useState(false);
    const [selectedParty, setSelectedParty] = useState(null);
    const [selectedPartyMembers, setSelectedPartyMembers] = useState([]);
    const selectOption = { label: 'Selecione', value: 0 };
    const { user, isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (isAuthenticated) {
                    const accessLevel = await userService.hasAccess(user.id);
                } else {
                    navigate('/login');
                }

                const accessLevel = await userService.hasAccess(user.id);

                setResetVisible(user.role === 1 || accessLevel.data == 1);

                const responseRoles = await lodgeRoleService.getAll();

                const roleData = responseRoles.data;

                const filteredRoles = roleData.filter(role => role.id >= 1 && role.id <= 7).sort((a, b) => a.id - b.id);

                const rolesWithMembers = await Promise.all(filteredRoles.map(async (role) => {
                    try {
                        const memberResponse = await memberService.getMemberByLodgeRole(role.id);
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: memberResponse.data ? memberResponse.data.id : ''
                        };
                    } catch (error) {
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: ''
                        };
                    }
                }));

                const filteredRoles2 = roleData.filter(role => role.id >= 8).sort((a, b) => a.id - b.id);
                const rolesWithMembers2 = await Promise.all(filteredRoles2.map(async (role) => {
                    try {
                        const memberResponse = await memberService.getMemberByLodgeRole(role.id);
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: memberResponse.data ? memberResponse.data.id : ''
                        };
                    } catch (error) {
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: ''
                        };
                    }
                }));

                const responseParties = await lodgePartyService.getAll();
                const partyData = responseParties.data;
                const partiesWithMembers = await Promise.all(partyData.map(async (party) => {
                    try {
                        const memberResponse = await memberService.getMembersByLodgeParty(party.id);
                        return {
                            partyId: party.id,
                            party: party.description,
                            members: Array.isArray(memberResponse.data) ? memberResponse.data.map(member => member.name).join('\n') : ''
                        };
                    } catch (error) {
                        return {
                            partyId: party.id,
                            party: party.description,
                            members: ''
                        };
                    }
                }));

                const response = await prmtsService.getByType("YEAR-BOARD");
                if (response.data) {
                    setYearDescription(response.data.description);
                }

                setRolesWithMembers(rolesWithMembers);
                setRolesWithMembers2(rolesWithMembers2);
                setPartiesWithMembers(partiesWithMembers);

                const responseMembers = await memberService.getAll();

                const memberOptions = responseMembers.data.map(member => ({
                    label: member.name,
                    value: member.id
                }));

                memberOptions.sort((a, b) => a.label.localeCompare(b.label));

                const listAllMembers = [selectOption, ...memberOptions];
                const listAllMembersParty = [...memberOptions];

                setListMembers(listAllMembers);
                setListMembersParty(listAllMembersParty);

            } catch (error) {
                messageError('Erro ao buscar informações.');
            }
        };

        fetchData();
    }, [navigate, userService, memberService, lodgeRoleService, lodgePartyService, prmtsService]);

    const handleEditYear = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setShowModalParty(false);
        if (showModalParty) {
            updatePartiesWithMembers();
        }
    };

    const handleSave = async () => {
        setShowModal(false);

        const response = await prmtsService.getByType('YEAR-BOARD');

        if (response.data) {
            await prmtsService.update({
                type: response.data.type,
                description: yearDescription,
            }).then(response => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await prmtsService.new({
                type: 'YEAR-BOARD',
                description: yearDescription,
            }).then(response => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };

    const handleResetBoardRoles = async () => {
        try {
            await memberService.clearBoardRoles();
            messageSuccess('Todos os cargos foram redefinidos com sucesso.');
            navigate('/board-members');
            return;
        } catch (error) {
            messageError('Erro ao redefinir os cargos.');
        }
    };

    const handleResetOtherRoles = async () => {
        try {
            await memberService.clearOtherRoles();
            messageSuccess('Todos os cargos foram redefinidos com sucesso.');
            navigate('/board-members');
            return;
        } catch (error) {
            messageError('Erro ao redefinir os cargos.');
        }
    };

    const handleResetParties = async () => {
        try {
            await memberService.clearParties();
            messageSuccess('Todas as comissões foram redefinidas com sucesso.');
            navigate('/board-members');
            return;
        } catch (error) {
            messageError('Erro ao redefinir as comissões.');
        }
    };

    const handleEditParty = async (party) => {
        setSelectedParty(party);
        const membersResponse = await memberService.getMembersByLodgeParty(party.partyId);
        setSelectedPartyMembers(Array.isArray(membersResponse.data) ? membersResponse.data.map(member => member.id) : []);
        setShowModalParty(true);
    };

    const handlePartyMemberChange = async (party, memberId) => {
        const responseParty = await lodgePartyService.getByDescription(party.party);
        const member = await memberService.getById(memberId);
        if (member.data.lodgeParty === responseParty.data.id) {
            await memberService.clearPartyMember(memberId);
        } else {
            await memberService.updatePartyMember(responseParty.data.id, memberId);
        }
        const updatedMembers = await memberService.getMembersByLodgeParty(responseParty.data.id);
        setSelectedPartyMembers(Array.isArray(updatedMembers.data) ? updatedMembers.data.map(member => member.id) : []);
    };

    const savePartyMembers = async () => {
        try {
            await memberService.updatePartyMembers(selectedParty.partyId, selectedPartyMembers);
            messageSuccess('Membros da comissão atualizados com sucesso.');
            handleClose();
        } catch (error) {
            messageError('Erro ao atualizar membros da comissão.');
        }
    };

    const handleRoleChange = async (roleId, memberId) => {
        try {
            await memberService.updateRoleMember(roleId, memberId);

            messageSuccess('Membro atualizado com sucesso.');
            setRolesWithMembers(prevState =>
                prevState.map(roleWithMember =>
                    roleWithMember.roleId === roleId ? { ...roleWithMember, member: memberId } : roleWithMember
                )
            );
            setRolesWithMembers2(prevState =>
                prevState.map(roleWithMember =>
                    roleWithMember.roleId === roleId ? { ...roleWithMember, member: memberId } : roleWithMember
                )
            );
        } catch (error) {
            messageError('Erro ao atualizar o membro.');
        }
    };

    const updatePartiesWithMembers = async () => {
        try {
            const responseParties = await lodgePartyService.getAll();
            const partyData = responseParties.data;
            const updatedPartiesWithMembers = await Promise.all(partyData.map(async (party) => {
                try {
                    const memberResponse = await memberService.getMembersByLodgeParty(party.id);
                    return {
                        partyId: party.id,
                        party: party.description,
                        members: Array.isArray(memberResponse.data) ? memberResponse.data.map(member => member.name).join('\n') : ''
                    };
                } catch (error) {
                    return {
                        partyId: party.id,
                        party: party.description,
                        members: ''
                    };
                }
            }));
            setPartiesWithMembers(updatedPartiesWithMembers);
        } catch (error) {
            messageError('Erro ao atualizar informações das comissões.');
        }
    };

    return (
        <>
            <Container>
                <h1 className="year-header">
                    {yearDescription}
                    {resetVisible && (
                        <Button
                            icon="pi pi-pencil"
                            className="small-button btn btn-info"
                            onClick={handleEditYear}
                        />
                    )}
                </h1>
                <div className="main-container">
                    <div className="cards-row">
                        <Card
                            title={
                                <div style={{ textAlign: 'center' }}>
                                    Cargos<br />
                                </div>
                            }
                            className="card-board"
                        >
                            <div className="responsive-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>Cargo</th>
                                            <th style={{ width: 'auto' }}>Irmão</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rolesWithMembers.map((roleWithMember, index) => (
                                            <tr key={index} className={index < 7 ? 'board-role-cell' : ''}>
                                                <td className={index < 7 ? 'board-role-cell' : ''}>{roleWithMember.role}</td>
                                                <td className={index < 7 ? 'board-role-cell' : ''}>
                                                    <SelectMenu
                                                        className="form-control"
                                                        list={listMembers}
                                                        value={roleWithMember.member}
                                                        onChange={(e) => handleRoleChange(roleWithMember.roleId, e.target.value)}
                                                        disabled={!resetVisible}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        {rolesWithMembers2.map((roleWithMember, index) => (
                                            <tr key={index + rolesWithMembers.length}>
                                                <td>{roleWithMember.role}</td>
                                                <td>
                                                    <SelectMenu
                                                        className="form-control"
                                                        list={listMembers}
                                                        value={roleWithMember.member}
                                                        onChange={(e) => handleRoleChange(roleWithMember.roleId, e.target.value)}
                                                        disabled={!resetVisible}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {resetVisible && (
                                <>
                                    <button onClick={handleResetBoardRoles} className="btn btn-warning reset-button">
                                        Limpar Diretoria
                                    </button>
                                    <button onClick={handleResetOtherRoles} className="btn btn-warning reset-button">
                                        Limpar Demais Cargos
                                    </button>
                                </>
                            )}
                        </Card>
                    </div>
                    <div className="cards-row">
                        <Card
                            title={
                                <div style={{ textAlign: 'center' }}>
                                    Comissões<br />
                                </div>
                            }
                            className="card-parties"
                        >
                            <div className="responsive-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>Cargo</th>
                                            <th style={{ width: 'auto' }}>Irmão</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partiesWithMembers.map((partyWithMembers, index) => (
                                            <tr key={index}>
                                                <td>{partyWithMembers.party}</td>
                                                <td>
                                                    {partyWithMembers.members.split('\n').map((member, idx) => (
                                                        <div key={idx}>{member}</div>
                                                    ))}
                                                </td>
                                                {resetVisible && (
                                                    <td>
                                                        <Button
                                                            icon="pi pi-pencil"
                                                            className="small-button btn btn-info"
                                                            onClick={() => handleEditParty(partyWithMembers)}
                                                        />
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {resetVisible && (
                                <button onClick={handleResetParties} className="btn btn-warning reset-button">
                                    Limpar Comissões
                                </button>
                            )}
                        </Card>
                        <div className="legend-container border border-3 w-30">
                            <div style={{ display: 'inline-block', padding: '10px' }}>
                                <p className="fw-bold">Legenda:</p>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="legend-color-box"></div>
                                    <p style={{ margin: 0 }}>Cargos da Diretoria</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar ano da gestão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Ano inicio / Ano fim</Form.Label>
                            <Form.Control type="text" value={yearDescription} onChange={e => setYearDescription(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Salvar Alterações
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalParty} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Comissão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formParty">
                            <Form.Label>Comissão</Form.Label>
                            <Form.Control type="text" value={selectedParty ? selectedParty.party : ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formMembers">
                            <Form.Label>Membros</Form.Label>
                            {listMembersParty.map(member => (
                                <Form.Check
                                    type="checkbox"
                                    key={member.value}
                                    label={member.label}
                                    checked={selectedPartyMembers.includes(member.value)}
                                    onChange={() => handlePartyMemberChange(selectedParty, member.value)}
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BoardMembers;
