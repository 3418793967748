import React, { useState, useEffect, useMemo, useContext } from "react";
import UserService from "../../app/service/userService.js";
import { messageSuccess, messageError } from "../../components/toastr";
import Card from "../../components/card";
import Container from "../../components/container";
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';


import Event_TypeService from "../../app/service/event_TypeService.js";
import EventTypeDT from "../eventType/eventTypeDT.js";
import { AuthContext } from '../../security/AuthContext';

const Config = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const userService = useMemo(() => new UserService(), []);
    const eventTypeService = useMemo(() => new Event_TypeService(), []);
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();



    const defaultOption = { label: 'Selecione', value: '' };
    const [eventTypesList, setEventTypesList] = useState([]);


    const EditEventType = (id) => {
        localStorage.setItem('eventType-edit', JSON.stringify(id));
        navigate('/eventType-register')

    }

    const DeleteEventType = async (id) => {

        await eventTypeService.deleteType(id).then((response) => {
            messageSuccess('Tipo de Sessão apagado com sucesso');
        }).catch((error) => {
            messageError(error.response.data);
            return;
        });

        window.location.reload();

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                localStorage.removeItem("children-edit");
                // Verificar se o usuário está logado
                if (isAuthenticated) {
                    const accessLevel = await userService.hasAccess(user.id);
                    setVisible(user.role === 1);
                } else {
                    navigate('/login');
                }

                const allEventTypes = await eventTypeService.getAll();
                const allEventTypeData = allEventTypes.data;
                setEventTypesList(allEventTypeData);


            } catch (error) {
                if (error.response && error.response.status === 400) {
                    messageError('Requisição inválida. Por favor, efetue o login.');
                } else {
                    messageError('Erro ao buscar informações.');
                }
            }
        }

        fetchData();
    }, []); // Run once when the component mounts


    return (
        <Container>
            <Card title="Configurações">
                <div style={{ paddingTop: '2vh', gap: '10px' }}>
                    <div>
                        <h4>Tipos de Sessões</h4>
                        <EventTypeDT
                            types={eventTypesList}
                            editEventType={EditEventType}
                            deleteEventType={DeleteEventType}
                        />
                        <Button
                            className="btn-sm btn btn-info"
                            onClick={() => navigate('/eventType-register')}
                            style={{ fontSize: "14px", height: '35px', marginTop: '2vh', marginRight: '0.5vw' }}
                        >
                            Cadastrar novo
                        </Button>

                    </div>
                </div>


            </Card>
        </Container >
    );
};

export default Config;
