import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import ReactDOM from 'react-dom';
import MemberService from "../../app/service/memberService";
import StatusService from "../../app/service/statusService";
import AttendanceService from "../../app/service/attendanceService.js";
import { messageError, messageSuccess } from "../../components/toastr";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import FormRow from '../../components/form-row';
import Container from "../../components/container";
import SelectMenu from "../../components/selectMenu";
import { useNavigate } from 'react-router-dom';
import AttendanceDT from "./attendanceDT";
import UserService from "../../app/service/userService.js";
import { Modal, Button } from 'react-bootstrap';
import EventService from "../../app/service/eventService.js";
import FormField from '../../components/form-field';
import VisitorService from "../../app/service/visitorService.js";
import VisitorAttendanceDT from "../visitor/visitorAttendanceDT.js";
import { formatCPF, validateCPF } from '../../utils/cpfUtils';
import { AuthContext } from "../../security/AuthContext";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import AttendanceCertificate from '../../components/AttendanceCertificate';
import { createRoot } from 'react-dom/client';

const AttendanceList = () => {
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const statusService = useMemo(() => new StatusService(), []);
    const attendanceService = useMemo(() => new AttendanceService(), []);
    const eventService = useMemo(() => new EventService(), []);
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const eventPicked = JSON.parse(localStorage.getItem('event-edit'));
    const [event, setEvent] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [name, setName] = useState('');
    const [cim, setCim] = useState('');
    const [degree, setDegree] = useState('');
    const [status, setStatus] = useState(0); // Set default value to 0
    const [listAllStatus, setListAllStatus] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedMembersExcuse, setSelectedMembersExcuse] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [guest, setGuest] = useState('');
    const visitorService = useMemo(() => new VisitorService(), []);
    const [visitorList, setVisitorList] = useState([]);
    const [allVisitorList, setAllVisitorList] = useState([]);
    const [selectedVisitors, setSelectedVisitors] = useState([]);
    const [showModalAddVisitor, setModalAddVisitor] = useState(false);
    const [visitorEmail, setVisitorEmail] = useState('');
    const [visitorPhone, setVisitorPhone] = useState('');
    const [visitorName, setVisitorName] = useState('');
    const [visitorCim, setVisitorCim] = useState('');
    const [visitorCpf, setVisitorCpf] = useState('');
    const [visitorDegree, setVisitorDegree] = useState('');
    const [visitorLodge, setVisitorLodge] = useState('');
    const [visitorLodgePotency, setVisitorLodgePotency] = useState('');
    const [visitorState, setVisitorState] = useState('');
    const [visitor, setVisitor] = useState('');
    const selectOption = { label: 'Selecione', value: 0 };
    const [CPF, setCPF] = useState('');
    const [cpfError, setCpfError] = useState('');
    const { user, isAuthenticated } = useContext(AuthContext);
    const [visitorData, setVisitorData] = useState([]);

    const handleSearch = async (degree) => {
        const memberFilter = {
            name: name,
            cim: cim,
            degree: degree
        };

        try {
            const response = await memberService.findMembersAttendance(memberFilter);
            if (Array.isArray(response.data) && response.data.length > 0) {
                const sortedMembers = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setSearchResults(sortedMembers);

                const attendancePromises = sortedMembers.map(async (member) => {

                    const attendanceResponse = await attendanceService.getAttMemberEvent({ member: member.id, event: eventPicked });
                    return { memberId: member.id, attendances: attendanceResponse.data };
                });

                const attendances = await Promise.all(attendancePromises);
                const presentMembers = attendances.filter(att => att.attendances.some(a => !a.excuse)).map(att => att.memberId);
                const excuseMembers = attendances.filter(att => att.attendances.some(a => a.excuse === 'S')).map(att => att.memberId);

                setSelectedMembers(presentMembers);
                setSelectedMembersExcuse(excuseMembers);


            } else {
                setSearchResults([]);
                messageError("Nenhum resultado encontrado.");
            }
        } catch (error) {
            messageError(`Erro ao realizar a busca. <br /> ${error.response.data}`);
        }
    };

    const handleCpfChange = async (inputCpf) => {
        const formattedCpf = formatCPF(inputCpf);
        setVisitorCpf(formattedCpf)
        if (!validateCPF(inputCpf)) {
            setCpfError('Digite um CPF válido');
        } else {
            setCpfError('');
            let lExistCpfMember = await memberService.validateCpf(inputCpf)
            let lExistCpfVisitor = await visitorService.validateCpf(inputCpf)
            if (lExistCpfMember.data < 0 || lExistCpfVisitor.data < 0) {
                setCpfError('CPF já cadastrado!');
            }
        }
    }

    const handleSelectMember = async (id) => {
        id = parseInt(id);

        const isSelected = selectedMembers.includes(id);

        const att = {
            member: id,
            event: eventPicked
        };
        const responseAtt = await attendanceService.getAttMemberEvent(att);

        try {

            if (isSelected) {
                await attendanceService.remove(responseAtt.data[0].id);
            } else {
                await attendanceService.add({ memberId: id, eventId: eventPicked });
            }

            setSelectedMembers((prevSelected) => {
                if (isSelected) {
                    return prevSelected.filter((memberId) => memberId !== id);
                } else {
                    return [...prevSelected, id];
                }
            });

            // Ensure member is not marked as excuse
            setSelectedMembersExcuse((prevSelected) => {
                return prevSelected.filter((memberId) => memberId !== id);
            });
        } catch (error) {
            messageError(`Erro ao atualizar a presença. <br /> ${error.response ? error.response.data : error.message}`);
        }
    };

    const handleSelectMemberExcuse = async (id) => {
        id = parseInt(id);
        const isSelected = selectedMembersExcuse.includes(id);
        const att = {
            member: id,
            event: eventPicked
        };
        const responseAtt = await attendanceService.getAttMemberEvent(att);
        try {
            if (isSelected) {
                await attendanceService.remove(responseAtt.data[0].id);
            } else {
                await attendanceService.add({ memberId: id, eventId: eventPicked, excuse: 'S' });
            }

            setSelectedMembersExcuse((prevSelected) => {

                if (isSelected) {
                    return prevSelected.filter((memberId) => memberId !== id);
                } else {
                    return [...prevSelected, id];
                }

            });

            // Ensure member is not marked as present
            setSelectedMembers((prevSelected) => {
                return prevSelected.filter((memberId) => memberId !== id);
            });
        } catch (error) {
            messageError(`Erro ao atualizar o abono. <br /> ${error.response ? error.response.data : error.message}`);
        }
    };

    const handleVisitorChoose = async () => {
        if (visitor == 0) {

            if (!visitorName || !visitorCim || !visitorDegree || !visitorCpf || !visitorLodge || !visitorLodgePotency || !visitorState) {
                messageError('Preencha todos os campos obrigatórios para continuar.');
                setModalAddVisitor(true);
                return;
            }
            try {
                await visitorService.new({
                    name: visitorName,
                    email: visitorEmail,
                    phoneCell: visitorPhone,
                    cim: visitorCim,
                    cpf: visitorCpf,
                    lodgeDegree: visitorDegree,
                    lodgePotency: visitorLodgePotency,
                    lodge: visitorLodge,
                    lodgeState: visitorState
                });
                messageSuccess('Visitante cadastrado com sucesso.');

                const response = await visitorService.getByCim(visitorCim);
                const newVisitorId = response.data.id;
                setVisitor(newVisitorId);

                // Aguarde a seleção do visitante
                await handleSelectVisitor(newVisitorId);
            } catch (error) {
                messageError(error.response.data);
                return;
            }
        } else {
            await handleSelectVisitor(visitor);
        }

        // Aguarde a atualização da lista de visitantes
        await fetchVisitors();

        // Limpe os campos do formulário e feche o modal
        handleCloseModal();
    };

    const handleSelectVisitor = async (id) => {
        try {
            const isSelected = selectedVisitors.includes(id);
            const att = { visitor: id, event: eventPicked };

            const responseAtt = await attendanceService.getAttVisitorEvent(att);
            const responseVisitor = await visitorService.getById(id);
            setVisitorData(responseVisitor.data);

            if (isSelected && responseAtt.data.length > 0) {
                await attendanceService.remove(responseAtt.data[0].id);
            } else {
                await attendanceService.addVisitorAttendance({ visitorId: id, eventId: eventPicked });
                messageSuccess("Presença registrada com sucesso.");

                // Gerar a imagem do certificado e enviar por e-mail
                await generateCertificateAndSendEmail(id, responseVisitor.data.email);
            }

            setSelectedVisitors((prevSelected) => {
                if (isSelected) {
                    return prevSelected.filter((visitorId) => visitorId !== id);
                } else {
                    return [...prevSelected, id];
                }
            });
        } catch (error) {
            messageError(`Erro ao registrar a presença do visitante. <br /> ${error.response ? error.response.data : error.message}`);
        }
    };

    const generateCertificateAndSendEmail = async (visitorId, email) => {
        try {
            // Obter dados do visitante
            const responseVisitor = await visitorService.getById(visitorId);
            const visitorData = responseVisitor.data;

            // Criar uma referência para o canvas
            const canvasRef = React.createRef();

            // Criar o elemento do certificado com o ref do canvas
            const certificateElement = (
                <AttendanceCertificate
                    ref={canvasRef}
                    event={event}
                    visitor={visitorData}
                    onRenderComplete={async () => {
                        const canvas = canvasRef.current;
                        if (!canvas) {
                            messageError('Erro ao acessar o canvas do certificado.');
                            return;
                        }

                        const imageData = canvas.toDataURL('image/png');

                        // Enviar a imagem para o backend
                        await sendCertificateByEmail(imageData, email);

                        // Remover o elemento temporário
                        root.unmount();
                        document.body.removeChild(container);
                    }}
                />
            );

            // Renderizar o componente em um elemento temporário
            const container = document.createElement('div');
            document.body.appendChild(container);
            const root = createRoot(container);
            root.render(certificateElement);

        } catch (error) {
            messageError('Erro ao gerar o certificado.');
        }
    };

    const sendCertificateByEmail = async (imageData, email) => {
        try {
            await attendanceService.sendCertificateByEmail({
                date: eventDate,
                email: email,
                imageData: imageData,
            });
            messageSuccess('Certificado enviado por e-mail com sucesso.');
        } catch (error) {
            messageError('Erro ao enviar o certificado por e-mail.');
        }
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalAddVisitor(false);
        setVisitorName("");
        setVisitorCim("");
        setVisitorDegree("");
        setVisitorEmail("");
        setVisitorPhone("");
        setVisitorLodge("");
        setVisitorLodgePotency("");
        setVisitorState("");
    };

    const confirmFinishAttendance = async () => {
        try {
            messageSuccess('Lista de presença registrada com sucesso');
            navigate("/calendar");
        } catch (error) {
            messageError(error.response ? error.response.data : error.message);
        } finally {
            handleCloseModal();
        }
    };

    // ... Função generateAttendanceCertificatePDF ...

    useEffect(() => {
        const fetchData = async () => {

            if (isAuthenticated) {
                const accessLevel = await userService.hasAccess(user.id);

                if (user.role !== 1 && accessLevel.data !== 1) {
                    messageError("Acesso negado. Procure um administrador.")
                    navigate('/login');
                }
            } else {
                messageError("Acesso negado. Procure um administrador.")
                navigate('/login');
            }

            if (!localStorage.getItem("event-edit")) {
                navigate('/calendar');
                return;
            }

            const response = await eventService.getById(localStorage.getItem("event-edit"))
            setEvent(response.data)

            const eventDate = new Date(response.data.dtStart[0], response.data.dtStart[1] - 1, response.data.dtStart[2], response.data.dtStart[3], response.data.dtStart[4]);

            const formattedDate = eventDate.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });

            const formattedDateTime = `${formattedDate}`;

            setEventDate(formattedDateTime);
            handleSearch(response.data.degree);
        };

        fetchData();
        fetchVisitors();
    }, [eventPicked]); // Run once when the component mounts

    // Defina fetchVisitors fora do useEffect
    const fetchVisitors = async () => {
        try {
            // Obtenha todos os visitantes
            const allVisitorsResponse = await visitorService.getAll();
            const allVisitors = allVisitorsResponse.data;

            // Verifique a presença de cada visitante no evento
            const attendancePromises = allVisitors.map(async (visitor) => {
                const attendanceResponse = await attendanceService.getAttVisitorEvent({ visitor: visitor.id, event: eventPicked });
                return { visitor, attendances: attendanceResponse.data };
            });

            const attendances = await Promise.all(attendancePromises);

            // Filtre os visitantes presentes
            const presentVisitors = attendances.filter(att => att.attendances.length > 0).map(att => att.visitor);

            setVisitorList(presentVisitors);

            // Atualize a lista de IDs de visitantes presentes
            const presentVisitorIds = presentVisitors.map(visitor => visitor.id);
            setSelectedVisitors(presentVisitorIds);

            // Atualize a lista de opções de visitantes
            const visitorOptions = [
                selectOption,
                ...allVisitors.map(visitor => ({
                    label: visitor.name + ' - ' + visitor.cim,
                    value: visitor.id
                }))
            ];
            setAllVisitorList(visitorOptions);
        } catch (error) {
            messageError(`Erro ao buscar visitantes. <br /> ${error.response ? error.response.data : error.message}`);
        }
    };

    return (
        <Container className="centralized-container">
            <h2 className="centralized-header">{`Lista de Presença - ${event.description} ` + ((event.degree) ? `- Grau: ${event.degree}` : '')}</h2>
            <h3 className="centralized-header" style={{ paddingBottom: '30px' }}>{`Data / Hora: ${eventDate}h`}</h3>
            <div className="card-container">
                <Card title={`Lista de Presença - Irmãos`}>
                    <FormRow>
                        <AttendanceDT
                            members={searchResults}
                            onSelectMember={handleSelectMember}
                            onSelectMemberExcuse={handleSelectMemberExcuse}
                            selectedMembers={selectedMembers}
                            selectedMembersExcuse={selectedMembersExcuse}
                        />
                    </FormRow>
                    <p>Membros presentes: {selectedMembers.length}</p>

                </Card>

                <Card title={`Lista de Presença - IIr∴ Visitantes`}>
                    <FormRow>
                        <VisitorAttendanceDT
                            visitors={visitorList}
                            onSelectVisitor={handleSelectVisitor}
                            selectedVisitors={selectedVisitors}
                        />
                    </FormRow>
                    <p>Visitantes presentes: {selectedVisitors.length}</p>
                    <br />
                    <FormRow>
                        <button className="btn btn-info" onClick={() => { setModalAddVisitor(true); setVisitor(0); }}>Incluir visitante</button>
                    </FormRow>
                </Card>

                <br />
                <FormRow>
                    <button className="btn btn-info" onClick={handleShowModal}>Concluir presença</button>
                </FormRow>

            </div >

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Presença</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Você selecionou {selectedMembers.length} membro(s) e {selectedVisitors.length} visitantes. Deseja confirmar?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={confirmFinishAttendance}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalAddVisitor} onHide={() => setModalAddVisitor(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Incluir Visitante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup label="Visitante já cadastrado: " htmlFor="selectVisitor">
                        <br />
                        <SelectMenu
                            className="form-control"
                            list={allVisitorList}
                            value={visitor}
                            onChange={(e) => setVisitor(e.target.value)}
                            style={{ maxWidth: '300px' }}
                        />
                    </FormGroup>
                    <br />
                    <h4>Cadastrar visitante novo</h4>
                    <FormField label="Nome" htmlFor="inputName">
                        <label htmlFor="inputName">Nome: *</label>
                        <input type="text" className="form-control" id="inputName" name="name" value={visitorName} onChange={(event) => { setVisitorName(event.target.value) }} placeholder="Nome do visitante" disabled={visitor != '0'} />
                    </FormField>
                    <FormField label="CIM" htmlFor="inputCIM">
                        <label htmlFor="inputCIM">CIM: *</label>
                        <input type="text" className="form-control" id="inputCIM" name="cim" value={visitorCim} onChange={(event) => { setVisitorCim(event.target.value) }} placeholder="CIM do visitante" disabled={visitor != '0'} />
                    </FormField>
                    <FormField>
                        <FormGroup
                            label="CPF: *"
                            htmlFor="inputCpf"
                            error={cpfError}
                        >
                            <input
                                className={`form-control ${cpfError ? 'is-invalid' : ''}`}
                                type="text"
                                id="inputCpf"
                                name="cpf"
                                value={visitorCpf}
                                placeholder="Digite o CPF"
                                onChange={(e) => handleCpfChange(e.target.value)}
                                disabled={visitor != '0'}
                            />
                            {cpfError && (
                                <div className="invalid-feedback">
                                    {cpfError}
                                </div>
                            )}
                        </FormGroup>
                    </FormField>
                    <FormField label="Grau" htmlFor="inputDegree">
                        <label htmlFor="inputDegree">Grau: *</label>
                        <input type="text" className="form-control" id="inputDegree" name="degree" value={visitorDegree} onChange={(event) => { setVisitorDegree(event.target.value) }} placeholder="Grau do visitante" disabled={visitor != '0'} />
                    </FormField>
                    <FormField label="Email" htmlFor="inputEmail">
                        <label htmlFor="inputEmail">Email:</label>
                        <input type="text" className="form-control" id="inputEmail" name="email" value={visitorEmail} onChange={(event) => { setVisitorEmail(event.target.value) }} placeholder="Email do visitante" disabled={visitor != '0'} />
                    </FormField>
                    <FormField label="Telefone" htmlFor="inputPhone">
                        <label htmlFor="inputPhone">Telefone:</label>
                        <input type="text" className="form-control" id="inputPhone" name="phone" value={visitorPhone} onChange={(event) => { setVisitorPhone(event.target.value) }} placeholder="Telefone do visitante" disabled={visitor != '0'} />
                    </FormField>
                    <FormField label="Lodge" htmlFor="inputLodge">
                        <label htmlFor="inputLodge">Aug∴ Loj∴ : *</label>
                        <input type="text" className="form-control" id="inputLodge" name="lodge" value={visitorLodge} onChange={(event) => { setVisitorLodge(event.target.value) }} placeholder="Loja do visitante" disabled={visitor != '0'} />
                    </FormField>
                    <FormField label="LodgePotency" htmlFor="inputLodgePotency">
                        <label htmlFor="inputLodgePotency">Pot∴ : *</label>
                        <input type="text" className="form-control" id="inputLodgePotency" name="lodge" value={visitorLodgePotency} onChange={(event) => { setVisitorLodgePotency(event.target.value) }} placeholder="Potência da Loja do visitante" disabled={visitor != '0'} />
                    </FormField>
                    <FormField label="Oriente" htmlFor="inputState">
                        <label htmlFor="inputState">Or∴ : *</label>
                        <input type="text" className="form-control" id="inputState" name="state" value={visitorState} onChange={(event) => { setVisitorState(event.target.value) }} placeholder="Oriente da loja do visitante" disabled={visitor != '0'} />

                    </FormField>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={handleVisitorChoose}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container >


    );

};

export default AttendanceList;
