import React, { useEffect, useState, useMemo, createRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import { messageError, messageSuccess } from '../../components/toastr';
import UserService from '../../app/service/userService';
import Loading from '../../components/loading';
import AttendanceService from '../../app/service/attendanceService';
import MemberService from '../../app/service/memberService';
import { Button, Modal } from 'react-bootstrap';
import { formatCPF, validateCPF } from '../../utils/cpfUtils.js';
import VisitorService from '../../app/service/visitorService';
import FormField from '../../components/form-field';
import EventService from '../../app/service/eventService.js';
import { createRoot } from 'react-dom/client';
import AttendanceCertificate from '../../components/AttendanceCertificate';

const LoginAttendance = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const eventId = queryParams.get('eventId');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const visitorService = useMemo(() => new VisitorService(), []);
    const eventService = useMemo(() => new EventService(), []);
    const attendanceService = useMemo(() => new AttendanceService(), []);
    const [loading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [bValidate, setBValidate] = useState(false);
    const [logged, setLogged] = useState(false);
    const [memberName, setMemberName] = useState('');
    const [memberId, setMemberId] = useState('');
    const [btnText, setBtnText] = useState('Carregando...');
    const [showModal, setShowModal] = useState(false);
    const [cpfError, setCpfError] = useState('');
    const [cpf, setCPF] = useState('');
    const [cim, setCim] = useState('');
    const [visitorLogged, setVisitorLogged] = useState(false);
    const [visitor, setVisitor] = useState(0);
    const [visitorName, setVisitorName] = useState('');
    const [visitorEmail, setVisitorEmail] = useState('');
    const [visitorPhone, setVisitorPhone] = useState('');
    const [visitorCim, setVisitorCim] = useState('');
    const [visitorCpf, setVisitorCpf] = useState('');
    const [visitorDegree, setVisitorDegree] = useState('');
    const [visitorLodge, setVisitorLodge] = useState('');
    const [visitorLodgePotency, setVisitorLodgePotency] = useState('');
    const [visitorState, setVisitorState] = useState('');
    const [showModalAddVisitor, setModalAddVisitor] = useState(false);
    const [visitorId, setVisitorId] = useState(0);
    const [showModalNewVisitor, setModalNewVisitor] = useState(false);
    const [showModalOldVisitor, setModalOldVisitor] = useState(false);
    const [event, setEvent] = useState({});

    const login = async () => {
        try {
            setLoading(true);
            const response = await userService.login({
                email: email,
                password: password
            });
            setMemberId(response.data.member);
            if (response.data.member == null) {
                messageError('Login/Senha não pertence a um membro cadastrado.');
                window.location.reload();
                setLoading(false);
                return;
            }
            const responseMember = await memberService.getById(response.data.member)
            setMemberName(responseMember.data.name);
            localStorage.setItem('token', response.data.token);
            const login = await userService.validate(response.data.token);
            if (!login.data) {
                messageError('Login/Senha inválido.');
                window.location.reload();
                setLoading(false);
                return;
            } else {
                setLogged(true)
            }
            setLoading(false);

            return;
        } catch (error) {
            setLoading(false);
            messageError('Erro ao realizar login. Verifique suas credenciais.');
        }
    };

    const handleVisitorLogin = async () => {
        try {
            setLoading(true);
            const response = await visitorService.getByCim(cim);
            if (response.data == null) {
                messageError('Visitante não encontrado.');
                setLoading(false);
                return;
            }
            //Checar se o visitante possui o CPF informado
            if (response.data.cpf != cpf) {
                messageError('CPF não confere com o do visitante informado.');
                setLoading(false);
                return;
            }
            setVisitor(response.data.id)
            registerAttendanceVisitor(response.data.id);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            messageError('Erro ao realizar login. Verifique suas credenciais.');
        }
    }

    const registerAttendance = async () => {
        try {
            setLoading(true);
            await attendanceService.registerAttendance({
                memberId: memberId,
                eventId: eventId
            });
            setLoading(false);
            messageSuccess("Presença registrada com sucesso.");
            setRegistered(true);
            navigate('/home');
        } catch (error) {
            setLoading(false);
            if (error.response) {
                messageError(error.response.data);
            }
        }
    };

    const registerAttendanceVisitor = async (id) => {
        try {
            setLoading(true);

            const responseEvent = await eventService.getById(eventId);
            const responseVisitor = await visitorService.getById(id);
            setEvent(responseEvent.data);

            await attendanceService.addVisitorAttendance({ visitorId: id, eventId: eventId }).then(async (response) => {
                messageSuccess("Presença registrada com sucesso.");

                // Generate the certificate and send the email
                await generateCertificateAndSendEmail(id, responseVisitor.data.email);
            }).catch(error => {
                messageError(error.response.data);
            });

            setLoading(false);
            navigate('/home');

        } catch (error) {
            setLoading(false);
            if (error.response) {
                messageError(error.response.data);
            }
        }
    };

    const generateCertificateAndSendEmail = async (visitorId, email) => {
        try {
            // Get visitor data
            const responseVisitor = await visitorService.getById(visitorId);
            const visitorData = responseVisitor.data;

            // Create a ref for the canvas
            const canvasRef = createRef();

            // Create the certificate element with the canvas ref
            const responseEvent = await eventService.getById(eventId);
            const certificateElement = (
                <AttendanceCertificate
                    ref={canvasRef}
                    event={responseEvent.data}
                    visitor={visitorData}
                />
            );

            // Render the component in a temporary element
            const container = document.createElement('div');
            document.body.appendChild(container);
            const root = createRoot(container);
            root.render(certificateElement);

            // Wait for rendering and generate the image
            setTimeout(async () => {
                const canvas = canvasRef.current;
                if (!canvas) {
                    messageError('Erro ao acessar o canvas do certificado.');
                    return;
                }

                const imageData = canvas.toDataURL('image/png');

                // Send the image to the backend
                await sendCertificateByEmail(imageData, email);

                // Remove the temporary element
                root.unmount();
                document.body.removeChild(container);
            }, 1000);
        } catch (error) {
            messageError('Erro ao gerar o certificado.');
        }
    };

    const sendCertificateByEmail = async (imageData, email) => {
        try {
            const responseEvent = await eventService.getById(eventId);
            const eventData = responseEvent.data;

            const formatDate = (dateArray) => {
                if (!dateArray) return "";
                const [year, month, day, hour, minutes] = dateArray;
                return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            };


            console.log("formatDate(eventData.dtStart)", formatDate(eventData.dtStart))
            await attendanceService.sendCertificateByEmail({
                date: formatDate(eventData.dtStart),
                email: email,
                imageData: imageData,
            });
            messageSuccess('Certificado enviado por e-mail com sucesso.');
        } catch (error) {
            messageError('Erro ao enviar o certificado por e-mail.');
        }
    };

    const handleCpfChange = async (inputCpf) => {
        const formattedCpf = formatCPF(inputCpf);
        setCPF(formattedCpf)
        setVisitorCpf(formattedCpf)

        if (inputCpf == '') {
            setCpfError('');
        }
        else if (!validateCPF(inputCpf)) {
            setCpfError('Digite um CPF válido');
        } else {
            setCpfError('');
            let lExistCpfMember = await memberService.validateCpf(inputCpf)
            let lExistCpfVisitor = await visitorService.validateCpf(inputCpf)
            if (lExistCpfMember.data < 0 || lExistCpfVisitor.data < 0) {
                setCpfError('CPF já cadastrado!');
            }
        }
    }

    const handleVisitorChoose = async () => {

        if (!visitorName || !visitorCim || !visitorDegree || !visitorCpf || !visitorLodge || !visitorLodgePotency || !visitorState) {
            messageError('Preencha todos os campos obrigatórios para continuar.');
            setModalAddVisitor(true);
            return;
        }
        try {
            await visitorService.new({
                name: visitorName,
                email: visitorEmail,
                phoneCell: visitorPhone,
                cim: visitorCim,
                cpf: formatCPF(visitorCpf),
                lodgeDegree: visitorDegree,
                lodgePotency: visitorLodgePotency,
                lodge: visitorLodge,
                lodgeState: visitorState
            });
            messageSuccess('Visitante cadastrado com sucesso.');

            const response = await visitorService.getByCim(visitorCim);
            setVisitorId(response.data.id)

            registerAttendanceVisitor(response.data.id)

        } catch (error) {
            messageError(error.response.data);
            return;
        }

        // Limpe os campos do formulário e feche o modal
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setModalAddVisitor(false);
        setVisitorName("");
        setVisitorCim("");
        setVisitorDegree("");
        setVisitorEmail("");
        setVisitorPhone("");
        setVisitorLodge("");
        setVisitorLodgePotency("");
        setVisitorState("");
        setVisitorCpf("");
        setCpfError("");
        setModalNewVisitor(false);
        setModalOldVisitor(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            await attendanceService.validateTimeRegister(eventId).then(response => {
                setBValidate(true);

            }).catch(error => {
                setBValidate(false);
            });
        };
        fetchData();
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {loading && (<Loading></Loading>)}

            {bValidate ? (
                <>
                    {logged ? (
                        <Card title="Registrar Presença">
                            <h3 style={{ marginTop: '2em' }}>Bem vindo, Ir∴ {memberName}</h3>
                            <div style={{ marginTop: '3em', textAlign: 'center' }}>
                                <button onClick={registerAttendance} className="btn btn-success">
                                    CONFIRMAR PRESENÇA
                                </button>
                            </div>
                        </Card>
                    ) : (
                        <>
                            <Card title="Registrar Presença">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="bs-component" style={{ padding: '20px' }}>
                                            <fieldset>
                                                <FormGroup label="Email: *" htmlFor="exampleInputEmail1">
                                                    <input
                                                        value={email}
                                                        type="email"
                                                        className="form-control"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Digite o Email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        style={{ padding: '10px 12px', height: '45px', width: '400px' }}
                                                    />
                                                </FormGroup>
                                                <br />
                                                <FormGroup label="Senha: *" htmlFor="exampleInputPassword1">
                                                    <input
                                                        value={password}
                                                        type="password"
                                                        className="form-control"
                                                        id="exampleInputPassword1"
                                                        placeholder="Digite a Senha"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        style={{ padding: '10px 12px', height: '45px', width: '400px' }}
                                                    />
                                                </FormGroup>
                                                <br />
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Button
                                                        className="btn-sm btn btn-success"
                                                        onClick={login}
                                                        style={{ fontSize: '14px', height: '35px', marginTop: '2vh' }}
                                                    >
                                                        ENTRAR
                                                    </Button>
                                                    <Button
                                                        className="btn-sm btn btn-info"
                                                        onClick={() => setModalAddVisitor(true)}
                                                        style={{ fontSize: '14px', height: '35px', marginTop: '2vh' }}
                                                    >
                                                        VISITANTE
                                                    </Button>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <br />

                        </>
                    )}
                </>
            ) : (
                <Card title="Registrar Presença">
                    <h3 style={{ marginTop: '2em' }}>Registro de presença fechado.</h3>
                    <h4 style={{ marginTop: '1em' }}>Procure alguém da diretoria.</h4>
                    <div style={{ marginTop: '3em', textAlign: 'center' }}>
                        <button onClick={() => { navigate("/home") }} className="btn btn-success">
                            Retornar ao site
                        </button>
                    </div>
                </Card>
            )
            }

            <Modal show={showModalAddVisitor} onHide={() => setModalAddVisitor(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Visitante</Modal.Title>
                </Modal.Header>

                <Button variant="success" onClick={() => { setModalOldVisitor(true); setModalNewVisitor(false) }}>
                    JÁ VISITEI A LOJA
                </Button>

                <br />

                <Button variant="success" onClick={() => { setModalNewVisitor(true); setModalOldVisitor(false) }}>
                    PRIMEIRA VEZ NA LOJA
                </Button>

                <br />

                <Modal.Body>
                    {showModalOldVisitor && (
                        <div>
                            <FormGroup label="CIM: *" htmlFor="exampleInputCim">
                                <input
                                    value={cim}
                                    type="cim"
                                    className="form-control"
                                    id="exampleInputCim"
                                    placeholder="Digite o CIM"
                                    onChange={(e) => setCim(e.target.value)}
                                    style={{ padding: '10px 12px', height: '45px', width: '400px' }}
                                />
                            </FormGroup>
                            <br />
                            <FormField>
                                <FormGroup
                                    label="CPF: *"
                                    htmlFor="inputCpf"
                                >
                                    <input
                                        className={`form-control`}
                                        type="text"
                                        id="inputCpf"
                                        name="cpf"
                                        value={cpf}
                                        placeholder="Digite o CPF"
                                        onChange={(e) => handleCpfChange(e.target.value)}
                                    />
                                </FormGroup>
                            </FormField>
                            <br />
                            <Button variant="success" onClick={handleVisitorLogin}>
                                CONFIRMAR PRESENÇA
                            </Button>
                        </div>
                    )}
                    {showModalNewVisitor && (
                        <div>
                            <br />
                            <h4>Cadastrar visitante novo</h4>
                            <br />
                            <FormField label="Nome" htmlFor="inputName">
                                <label htmlFor="inputName">Nome *:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputName"
                                    name="name"
                                    value={visitorName}
                                    onChange={(event) => setVisitorName(event.target.value)}
                                    placeholder="Nome do visitante"
                                />
                            </FormField>
                            <FormField label="CIM" htmlFor="inputCIM">
                                <label htmlFor="inputCIM">CIM: *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputCIM"
                                    name="cim"
                                    value={visitorCim}
                                    onChange={(event) => setVisitorCim(event.target.value)}
                                    placeholder="CIM do visitante"
                                />
                            </FormField>
                            <FormField>
                                <FormGroup label="CPF: *" htmlFor="inputCpf" error={cpfError}>
                                    <input
                                        className={`form-control ${cpfError ? 'is-invalid' : ''}`}
                                        type="text"
                                        id="inputCpf"
                                        name="cpf"
                                        value={visitorCpf}
                                        placeholder="Digite o CPF"
                                        onChange={(e) => handleCpfChange(e.target.value)}
                                    />
                                    {cpfError && <div className="invalid-feedback">{cpfError}</div>}
                                </FormGroup>
                            </FormField>

                            <FormField label="Grau *" htmlFor="inputDegree">
                                <label htmlFor="inputDegree">Grau: *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputDegree"
                                    name="degree"
                                    value={visitorDegree}
                                    onChange={(event) => setVisitorDegree(event.target.value)}
                                    placeholder="Grau do visitante"
                                />
                            </FormField>
                            <FormField label="Email" htmlFor="inputEmail">
                                <label htmlFor="inputEmail">Email:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputEmail"
                                    name="email"
                                    value={visitorEmail}
                                    onChange={(event) => setVisitorEmail(event.target.value)}
                                    placeholder="Email do visitante"
                                />
                            </FormField>
                            <FormField label="Telefone" htmlFor="inputPhone">
                                <label htmlFor="inputPhone">Telefone:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputPhone"
                                    name="phone"
                                    value={visitorPhone}
                                    onChange={(event) => setVisitorPhone(event.target.value)}
                                    placeholder="Telefone do visitante"
                                />
                            </FormField>
                            <FormField label="Lodge" htmlFor="inputLodge">
                                <label htmlFor="inputLodge">Aug∴ Loj∴ : *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputLodge"
                                    name="lodge"
                                    value={visitorLodge}
                                    onChange={(event) => setVisitorLodge(event.target.value)}
                                    placeholder="Loja do visitante"
                                />
                            </FormField>
                            <FormField label="LodgePotency" htmlFor="inputLodgePotency">
                                <label htmlFor="inputLodgePotency">Pot∴ : *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputLodgePotency"
                                    name="lodge"
                                    value={visitorLodgePotency}
                                    onChange={(event) => setVisitorLodgePotency(event.target.value)}
                                    placeholder="Potência da Loja do visitante"
                                />
                            </FormField>
                            <FormField label="Oriente" htmlFor="inputState">
                                <label htmlFor="inputState">Or∴ : *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputState"
                                    name="state"
                                    value={visitorState}
                                    onChange={(event) => setVisitorState(event.target.value)}
                                    placeholder="Oriente da loja do visitante"
                                />
                            </FormField>
                            <br />
                            <Button variant="success" onClick={handleVisitorChoose}>
                                Confirmar cadastro e presença
                            </Button>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancelar
                    </Button>

                </Modal.Footer>
            </Modal>


        </div >
    );
};

export default LoginAttendance;
