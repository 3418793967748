import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button } from 'primereact/button';
import UserService from "../../app/service/userService.js";
import { useNavigate } from 'react-router-dom';
import MemberService from "../../app/service/memberService.js";
import { messageError } from "../../components/toastr";
import SpouseService from "../../app/service/spouseService.js";
import { AuthContext } from '../../security/AuthContext';

const calculateAge = (birthDateArray) => {
    if (!birthDateArray || birthDateArray.length !== 3) {
        return null;
    }

    const today = new Date();
    const birthDateObj = new Date(birthDateArray[0], birthDateArray[1] - 1, birthDateArray[2]);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
};

const formatWithLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
};

const formatDate = (birthDateArray) => {
    if (!birthDateArray || birthDateArray.length !== 3) {
        return "";
    }

    const [ano, mes, dia] = birthDateArray;
    const formattedDia = formatWithLeadingZero(dia);
    const formattedMes = formatWithLeadingZero(mes);

    return `${formattedDia}/${formattedMes}/${ano}`;
};

const SpouseDT = (props) => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const spouseService = useMemo(() => new SpouseService(), []);
    const [rows, setRows] = useState([]);  // State para armazenar os rows
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Verificar se o usuário está logado

                if (isAuthenticated) {
                    const accessLevel = await userService.hasAccess(user.id);
                    setVisible(user.role === 1 || accessLevel.data === 1);
                    await processSpouses(props.spouses);
                } else {
                    navigate('/login');
                }

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [props.spouses, userService, navigate]);

    async function processSpouses(spouses) {
        const spouseDetails = await Promise.all(spouses.map(async (spouse) => {
            const response = await memberService.getById(spouse.member);
            return {
                ...spouse,
                memberDetails: response.data,
            };
        }));

        const newRows = spouseDetails.map((spouse, index) => {
            const age = calculateAge(spouse.dtBorn);
            const ageMarry = calculateAge(spouse.dtMarry);
            return (
                <tr key={spouse.id} style={{ height: '30px' }}>
                    <td style={{ padding: '5px', width: 'auto' }}>{index + 1}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{spouse.name}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{spouse.email}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>{spouse.phoneCell}</td>
                    <td style={{ padding: '5px', width: 'auto', paddingLeft: '1.4vw' }}>{formatDate(spouse.dtBorn)}</td>
                    <td style={{ padding: '5px', width: 'auto', paddingLeft: '1.4vw' }}>{spouse.memberDetails.name}</td>
                    <td style={{ padding: '5px', width: 'auto' }}>
                        {(visible || user.member === spouse.member) && (
                            <Button
                                icon="pi pi-pencil"
                                rounded outlined severity="success"
                                aria-label="Search"
                                className="small-button btn btn-info"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.editSpouse(spouse.id)}
                            />)}
                        {' '}
                        {(visible) && (
                            <Button
                                icon="pi pi-times"
                                rounded outlined severity="danger"
                                aria-label="Cancel"
                                className="small-button btn btn-danger"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.deleteSpouse(spouse.id)}
                            />)}
                    </td>
                </tr>
            );
        });

        setRows(newRows);
    }

    return (
        <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th style={{ width: 'auto' }}>#</th>
                        <th style={{ width: 'auto' }}>Nome</th>
                        <th style={{ width: 'auto', paddingLeft: '2vw' }}>Email</th>
                        <th style={{ width: 'auto', paddingLeft: '2vw' }}>Celular</th>
                        <th style={{ width: 'auto', paddingLeft: '1.4vw' }}>Nascimento</th>
                        <th style={{ width: 'auto', paddingLeft: '1.4vw' }}>Casada com</th>
                        {visible && (
                            <>
                                <th style={{ width: 'auto' }}>Ações</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <div className="mt-4">
                Total de Cunhadas: {props.spouses.length}
            </div>
        </>
    );
};

export default SpouseDT;
