import React, { useState, useEffect, useMemo } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import RoleService from '../../app/service/roleService';
import StatusService from '../../app/service/statusService';
import { messageSuccess, messageError } from '../../components/toastr';

function EditUser() {
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const roleService = useMemo(() => new RoleService(), []);
    const statusService = useMemo(() => new StatusService(), []);
    const [visible, setVisible] = useState(false);

    const userEdit = useMemo(() => {
        const storedUserEdit = localStorage.getItem('user_edit');
        return storedUserEdit ? JSON.parse(storedUserEdit) : {};
    }, []);

    const [id] = useState(userEdit);
    const [email, setEmail] = useState(userEdit.email);
    const [password, setPassword] = useState(userEdit.password);
    const [role, setSelectedRole] = useState(userEdit.role);
    const [roleId, setSelectedRoleId] = useState(0);
    const [status, setSelectedStatus] = useState(userEdit.status);
    const [statusId, setSelectedStatusId] = useState(0);
    const [member, setMember] = useState(userEdit.member);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');

    const validateEmail = (inputEmail) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    const handleEmailChange = (inputEmail) => {
        setEmail(inputEmail);
        if (!validateEmail(inputEmail)) {
            setEmailError('Digite um email válido');
        } else {
            setEmailError('');
        }
    };

    const handlePasswordChange = (inputPassword) => {
        setPassword(inputPassword);
        if (inputPassword === null || inputPassword === '') {
            setPasswordError('Digite uma senha válida.');
        } else {
            setPasswordError('');
        }
    };

    const handleRoleChange = async (inputRole) => {
        const response = await roleService.getByDescription(inputRole);
        setSelectedRole(inputRole);
        setSelectedRoleId(response.data.id);
    };

    const handleStatusChange = async (inputStatus) => {
        const response = await statusService.getByDescription(inputStatus);
        setSelectedStatus(inputStatus);
        setSelectedStatusId(response.data.id);
    };

    const cancelar = () => {
        localStorage.removeItem('user_edit');
        navigate('/user-list');
        return;
    };

    const edit = async () => {
        if (!validateEmail(email)) {
            setEmailError('Digite um email válido');
            messageError('Email inválido. Favor inserir email válido.');
            return;
        }

        // Limpar os erros se o cadastro for bem-sucedido
        setPasswordError('');
        setEmailError('');

        const updatedUserData = {
            id: id,
            email: email,
            password: password,
            role: roleId,
            status: statusId,
            member: member
        };

        await userService.edit(updatedUserData)
            .then((response) => {
                messageSuccess('Usuário alterado com sucesso.');
                localStorage.removeItem('user_edit');
                navigate('/user-list');
                return;
            })
            .catch((error) => {
                messageError(error.response.data);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            //Verificar se o usuário está logado
            const token = localStorage.getItem("token");
            if (token) {
                const login = await userService.validate(token);
                if (!login.data) {
                    messageError("Acesso negado. Procure um administrador.")
                    navigate('/login');
                    return;
                }
                const user = await userService.getByToken(token);

                const accessLevel = await userService.hasAccess(user.data.id);
                if (user.data.role != 1 && accessLevel.data != 1 && user.data.id != userEdit) {
                    messageError("Você não possui acesso.")
                    navigate('/home');
                    return;
                }
                setVisible(user.data.role === 1);

            } else {
                navigate('/login');
                return;
            }

            try {
                const response = await userService.getById(userEdit);
                if (response && response.data) {
                    setEmail(response.data.email);
                    setPassword(response.data.password);
                    setSelectedRole(response.data.role.description);
                    setSelectedStatus(response.data.status.description);
                    setSelectedRoleId(response.data.role.id);
                    setSelectedStatusId(response.data.status.id);
                    setMember(response.data.member)
                } else {
                    messageError("Usuário não encontrado.");
                }
            } catch (error) {
                messageError("Erro na busca da informação.");
            }
        }
        fetchData();
    }, [userEdit, userService]);


    return (
        <div>
            <Container>
                <Card title="Alterar informações">
                    <Container>
                        <fieldset>
                            <div className="col-lg-4">
                                <FormGroup
                                    label="Email: *"
                                    htmlFor="inputEmail"
                                    error={emailError}
                                >
                                    <input
                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        type="email"
                                        id="inputEmail"
                                        name="email"
                                        placeholder="Digite o Email"
                                        value={email}
                                        onChange={(e) => handleEmailChange(e.target.value)}
                                    />
                                    {emailError && (
                                        <div className="invalid-feedback">
                                            {emailError}
                                        </div>
                                    )}
                                </FormGroup>

                                <FormGroup
                                    label="Senha: *"
                                    htmlFor="inputPassword"
                                >
                                    <input
                                        className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                        type="password"
                                        id="inputPassword"
                                        name="password"
                                        placeholder="Digite a Senha"
                                        value={password}
                                        onChange={(e) => handlePasswordChange(e.target.value)}
                                    />
                                    {passwordError && (
                                        <div className="invalid-feedback">
                                            {passwordError}
                                        </div>
                                    )}
                                </FormGroup>
                            </div>
                            <br />
                            {visible && (
                                <>
                                    <FormGroup
                                        label="Cargo: *"
                                        htmlFor="inputCargo"
                                    >
                                        <br />
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionsRadios1"
                                            id="optionsRadios0"
                                            value="Administrador"
                                            onChange={(e) => handleRoleChange(e.target.value)}
                                            checked={role === "Administrador"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="optionsRadios0">
                                            Administrador
                                        </label>
                                        <br />
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionsRadios1"
                                            id="optionsRadios1"
                                            value="Irmão"
                                            onChange={(e) => handleRoleChange(e.target.value)}
                                            checked={role === "Irmão"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="optionsRadios1">
                                            Irmão
                                        </label>
                                        <br />
                                    </FormGroup>
                                    <br />
                                    <FormGroup
                                        label="Status: *"
                                        htmlFor="inputStatus"
                                    >
                                        <br />
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionsRadios2"
                                            id="optionsRadios0"
                                            value="Ativo"
                                            onChange={(e) => handleStatusChange(e.target.value)}
                                            checked={status === "Ativo"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="optionsRadios0">
                                            Ativo
                                        </label>
                                        <br />
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionsRadios2"
                                            id="optionsRadios1"
                                            value="Inativo"
                                            onChange={(e) => handleStatusChange(e.target.value)}
                                            checked={status === "Inativo"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="optionsRadios1">
                                            Inativo
                                        </label>
                                        <br />
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionsRadios2"
                                            id="optionsRadios2"
                                            value="Suspenso"
                                            onChange={(e) => handleStatusChange(e.target.value)}
                                            checked={status === "Suspenso"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="optionsRadios2">
                                            Suspenso
                                        </label>
                                        <br />
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionsRadios2"
                                            id="optionsRadios2"
                                            value="Afastado"
                                            onChange={(e) => handleStatusChange(e.target.value)}
                                            checked={status === "Afastado"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="optionsRadios2">
                                            Afastado
                                        </label>
                                    </FormGroup>
                                </>
                            )}

                        </fieldset>
                    </Container>
                </Card>

                <button onClick={edit} className="btn btn-success">
                    Confirmar
                </button>
                {' '}
                <button onClick={cancelar} className="btn btn-danger">
                    Cancelar
                </button>
            </Container >
        </div>
    );
}

export default EditUser;
