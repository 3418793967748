import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import FormField from '../../components/form-field';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import { messageSuccess, messageError } from '../../components/toastr';
import { format, parse } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import SelectMenu from "../../components/selectMenu";
import DatePicker from 'react-datepicker';
import { AuthContext } from '../../security/AuthContext';
import VisitorService from '../../app/service/visitorService';
import MemberService from '../../app/service/memberService';
import { formatCPF, validateCPF } from '../../utils/cpfUtils.js';

function VisitorRegister() {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [visitor, setVisitor] = useState(null);
    const visitorEdit = JSON.parse(localStorage.getItem('visitor-edit'));
    const visitorService = useMemo(() => new VisitorService(), []);
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [cim, setCim] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [lodge, setLodge] = useState('');
    const [lodgePotency, setLodgePotency] = useState('');
    const [lodgeState, setLodgeState] = useState('');
    const [lodgeDegree, setLodgeDegree] = useState('');
    const [phoneCell, setPhoneCell] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [cpfError, setCpfError] = useState('');
    const [cimError, setCimError] = useState('');
    const [bEdit, setBEdit] = useState(false);

    const selectOption = { label: 'Selecione', value: '0' };
    const listSN = [
        { label: 'Não', value: 'N' },
        { label: 'Sim', value: 'S' }
    ];

    const validateEmail = (inputEmail) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    const handleNameChange = (inputName) => {
        setName(inputName);
        setNameError('');
    };

    const handleEmailChange = (inputEmail) => {
        setEmail(inputEmail);

        if (!validateEmail(inputEmail)) {
            setEmailError('Digite um email válido');
        } else {
            setEmail(inputEmail);
            setEmailError('');
        }
        if (inputEmail === '') {
            setEmail(inputEmail);
            setEmailError('');
        }
    };

    const register = async () => {
        if (name === '') {
            messageError('Nome inválido');
            return;
        }

        if (email === '' || emailError) {
            messageError('Email inválido');
            return;
        }

        if (cpfError !== '') {
            messageError("CPF inválido");
            return;
        }

        if (cimError !== '') {
            messageError("CIM inválido");
            return;
        }

        setNameError('');
        setCpfError('');
        setCimError('');

        if (visitorEdit) {
            await visitorService.update({
                id: id,
                name: name,
                email: email,
                phoneCell: phoneCell,
                cim: cim,
                cpf: cpf,
                lodge: lodge,
                lodgePotency: lodgePotency,
                lodgeState: lodgeState,
                lodgeDegree: lodgeDegree
            }).then(response => {
                messageSuccess('Visitante atualizado com sucesso.');
                navigate('/visitor-list');
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await visitorService.new({
                name: name,
                email: email,
                phoneCell: phoneCell,
                cim: cim,
                cpf: cpf,
                lodge: lodge,
                lodgePotency: lodgePotency,
                lodgeState: lodgeState,
                lodgeDegree: lodgeDegree
            }).then(response => {
                messageSuccess('Visitante cadastrado com sucesso.');
                navigate('/visitor-list');
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, ''); // Remove qualquer caracter não numérico
        const match = cleaned.match(/^(\d{2})(\d{1,5})?(\d{0,4})$/);
        if (match) {
            const part1 = match[1] ? `(${match[1]})` : '';
            const part2 = match[2] ? ` ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`;
        }
        return phoneNumber; // Retorna o original se não houver correspondência
    };

    const handlePhoneCellChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneCell(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };

    const handleCimChange = async (inputCim) => {
        setCim(inputCim)
        if (inputCim) {

            let lExistCimMember = await memberService.validateCim(inputCim)
            if (lExistCimMember.data < 0) {
                setCimError('Este CIM pertence a um irmão.');
            }

            let lExistCimVisitor = await visitorService.validateCim(inputCim)
            if (lExistCimVisitor.data < 0) {

                const response = await visitorService.getByCim(inputCim);

                if (response.data.id != visitorEdit) {
                    setCimError('CIM já cadastrado.');
                }

            } else {
                setCimError('');
            }
        }
    }

    const handleCpfChange = async (inputCpf) => {
        const formattedCpf = formatCPF(inputCpf);
        setCpf(formattedCpf)
        if (!validateCPF(inputCpf)) {
            setCpfError('Digite um CPF válido');
        } else {
            setCpfError('');

            let lExistCpfMember = await memberService.validateCpf(inputCpf)
            if (lExistCpfMember.data < 0) {
                setCpfError('CPF já cadastrado!');
            }

            let lExistCpfVisitor = await memberService.validateCpf(inputCpf)
            if (lExistCpfVisitor.data < 0 && !visitorEdit) {
                setCpfError('CPF já cadastrado!');
            }
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                const accessLevel = await userService.hasAccess(user.id);
                setVisible(user.role === 1 || accessLevel.data === 1);

                if (visitorEdit) {
                    setBEdit(true)
                    const response = await visitorService.getById(visitorEdit);
                    setVisitor(response.data);
                    const visitorData = response.data;

                    setId(visitorData.id);
                    setName(visitorData.name);
                    setEmail(visitorData.email);
                    setPhoneCell(visitorData.phoneCell)
                    setCpf(visitorData.cpf);
                    setCim(visitorData.cim);
                    setLodge(visitorData.lodge);
                    setLodgePotency(visitorData.lodgePotency);
                    setLodgeState(visitorData.lodgeState);
                    setLodgeDegree(visitorData.lodgeDegree);

                    if (user.role != 1 && accessLevel.data != 1) {
                        messageError("Você não possui acesso.");
                        navigate('/visitor-list');
                        return;
                    }
                }

            } else {
                navigate('/login');
                return;
            }


        };

        fetchData();
        //localStorage.removeItem("spouse-edit")

    }, [visitorEdit, navigate, userService, visitorService]);

    return (
        <div>
            <Container>
                <Card title="Cadastro de visitante">
                    <Container>
                        <fieldset>
                            <br />
                            <FormGroup label="Nome: *" htmlFor="inputName" error={nameError}>
                                <input
                                    className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                    type="text"
                                    id="inputName"
                                    name="name"
                                    value={name}
                                    placeholder="Digite o nome"
                                    onChange={(e) => handleNameChange(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                />
                                {nameError && (
                                    <div className="invalid-feedback">
                                        {nameError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormGroup
                                label="CIM: *"
                                htmlFor="inputCim"
                                error={cimError}
                            >
                                <input
                                    className={`form-control ${cimError ? 'is-invalid' : ''}`}
                                    type="text"
                                    id="inputCim"
                                    name="cim"
                                    value={cim}
                                    placeholder="Digite o CIM"
                                    onChange={(e) => handleCimChange(e.target.value)}
                                />
                                {cimError && (
                                    <div className="invalid-feedback">
                                        {cimError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormGroup
                                label="CPF: *"
                                htmlFor="inputCpf"
                                error={cpfError}
                            >
                                <input
                                    className={`form-control ${cpfError ? 'is-invalid' : ''}`}
                                    type="text"
                                    id="inputCpf"
                                    name="cpf"
                                    value={cpf}
                                    placeholder="Digite o CPF"
                                    onChange={(e) => handleCpfChange(e.target.value)}
                                />
                                {cpfError && (
                                    <div className="invalid-feedback">
                                        {cpfError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormGroup label="Email: " htmlFor="inputEmail" error={emailError}>
                                <input
                                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                    type="email"
                                    id="inputEmail"
                                    name="email"
                                    value={email}
                                    placeholder="Digite o email"
                                    onChange={(e) => handleEmailChange(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                />
                                {emailError && (
                                    <div className="invalid-feedback">
                                        {emailError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                <label htmlFor="inputPhoneCell">Telefone Celular:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: '22%' }}
                                    id="inputPhoneCell"
                                    name="phoneCell"
                                    placeholder="Digite o Celular"
                                    value={phoneCell}
                                    onChange={handlePhoneCellChange}
                                />
                            </FormField>
                            <br />
                            <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                <label htmlFor="inputLodgeDegree">Grau:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: '22%' }}
                                    id="inputLodgeDegree"
                                    name="lodgeDegree"
                                    placeholder="Digite o grau do irmão visitante"
                                    value={lodgeDegree}
                                    onChange={(e) => setLodgeDegree(e.target.value)}
                                />
                            </FormField>
                            <br />
                            <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                <label htmlFor="inputLodge">Loja:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: '22%' }}
                                    id="inputLodge"
                                    name="lodge"
                                    placeholder="Digite o nome da loja"
                                    value={lodge}
                                    onChange={(e) => setLodge(e.target.value)}
                                />
                            </FormField>
                            <br />
                            <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                <label htmlFor="inputLodgePotency">Potência:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: '22%' }}
                                    id="inputLodgePotency"
                                    name="lodgePotency"
                                    placeholder="Qual a potênica da loja"
                                    value={lodgePotency}
                                    onChange={(e) => setLodgePotency(e.target.value)}
                                />
                            </FormField>
                            <br />
                            <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                <label htmlFor="inputLodgeState">Oriente:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: '22%' }}
                                    id="inputLodgeState"
                                    name="lodgeState"
                                    placeholder="Digite o oriente da loja"
                                    value={lodgeState}
                                    onChange={(e) => setLodgeState(e.target.value)}
                                />
                            </FormField>
                        </fieldset>
                    </Container>
                </Card>

                <button onClick={register} className="btn btn-success">
                    Cadastrar
                </button>
                {' '}
                <button onClick={e => navigate('/spouse-list')} className="btn btn-danger">
                    Cancelar
                </button>
            </Container>
        </div>
    );
}

export default VisitorRegister;
