import React, { useState, useEffect, useMemo } from 'react';
import UserService from '../app/service/userService';
import { useNavigate } from 'react-router-dom';
import PRMTSService from '../app/service/prmtsService';
import MemberService from '../app/service/memberService';
import EventService from '../app/service/eventService';
import SelectMenu from "../components/selectMenu";
import TitleLMPHB from '../components/titleLMPHB';
import { messageSuccess, messageError } from '../components/toastr';
import LodgeRoleService from '../app/service/lodgeRoleService';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import 'moment/locale/pt-br';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css'; // Import PrimeIcons CSS

function Home() {
    const userService = useMemo(() => new UserService(), []);
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const lodgeRoleService = useMemo(() => new LodgeRoleService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const eventService = useMemo(() => new EventService(), []); // Crie uma instância de EventService
    const [nextEvent, setNextEvent] = useState(null); // Estado para armazenar o próximo evento
    const [listMembers, setListMembers] = useState([]);
    const navigate = useNavigate();
    const [dayTimeSession, setDayTimeSession] = useState({});
    const [dayTimeSessionDescription, setDayTimeSessionDescription] = useState('');
    const [rolesWithMembers, setRolesWithMembers] = useState([]);
    const [visible, setVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const selectOption = { label: '', value: 0 };
    const [title, setTitle] = useState('');
    const [paragraph, setParagraph] = useState('');
    const [author, setAuthor] = useState('');
    const [titleAbout, setTitleAbout] = useState();
    const [paragraphAbout, setParagraphAbout] = useState('');
    const [authorAbout, setAuthorAbout] = useState('');
    const [year, setYear] = useState('');

    const localizer = momentLocalizer(moment);
    moment.locale('pt-br');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await prmtsService.getByType('DAYTIME-SESSION');
                if (response.data) {
                    setDayTimeSession(response.data);
                    setDayTimeSessionDescription(response.data.description);
                }
                const responseTitle = await prmtsService.getByType("ABOUT-TITLE");
                if (responseTitle.data) {
                    setTitle(responseTitle.data.description);
                    setTitleAbout(responseTitle.data);
                }

                const responseParagraph = await prmtsService.getByType("ABOUT-PARAGRAPH");
                if (responseParagraph.data) {
                    setParagraph(responseParagraph.data.description);
                    setParagraphAbout(responseParagraph.data);
                }

                const responseAuthor = await prmtsService.getByType("ABOUT-AUTHOR");
                if (responseAuthor.data) {
                    const authorText = responseAuthor.data.description.split(',')[0];
                    setAuthor(authorText);
                    setAuthorAbout(responseAuthor.data);
                    const yearMatch = responseAuthor.data.description.match(/,\s*(\d{4})$/);
                    if (yearMatch) {
                        setYear(yearMatch[1]);
                    }
                }

                const responseRoles = await lodgeRoleService.getAll();
                const roleData = responseRoles.data;
                const filteredRoles = roleData.filter(role => role.id >= 1 && role.id <= 7).sort((a, b) => a.id - b.id);

                const rolesWithMembers = await Promise.all(filteredRoles.map(async (role) => {
                    try {
                        const memberResponse = await memberService.getMemberByLodgeRole(role.id);
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: memberResponse.data ? memberResponse.data.id : ''
                        };
                    } catch (error) {
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: ''
                        };
                    }
                }));
                setRolesWithMembers(rolesWithMembers);

                // Aqui adicionamos o nome e telefone no listMembers corretamente
                const responseMembers = await memberService.getAll();
                const memberOptions = responseMembers.data.map(member => ({
                    label: member.name,
                    value: member.id,
                    phone: member.phoneCell // Inclui o telefone aqui
                }));
                setListMembers(memberOptions); // Atualizamos listMembers com os dados completos


                const responseEvent = await eventService.getNextEvent(); // Suponha que existe uma função para buscar o próximo evento
                if (responseEvent.data) {
                    const event = responseEvent.data[0];
                    setNextEvent({
                        ...event,
                        start: new Date(event.dtStart[0], event.dtStart[1] - 1, event.dtStart[2], event.dtStart[3], event.dtStart[4]),
                        end: new Date(event.dtEnd[0], event.dtEnd[1] - 1, event.dtEnd[2], event.dtEnd[3], event.dtEnd[4]),
                    });

                }
            } catch (error) {
                messageError('Erro ao buscar dados: ' + error.message);
            }
        };

        fetchData();
    }, [navigate, userService, prmtsService]);


    const handleEditDayTime = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleSave = async () => {
        setShowModal(false);

        const response = await prmtsService.getByType('DAYTIME-SESSION');

        if (response.data) {
            await prmtsService.update({
                type: dayTimeSession.type,
                description: dayTimeSessionDescription,
            }).then(response => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await prmtsService.new({
                type: 'DAYTIME-SESSION',
                description: dayTimeSessionDescription,
            }).then(response => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };

    const handleEditAbout = () => {
        setShowModal(true);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TitleLMPHB></TitleLMPHB>
            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '1vw', textAlign: 'justify', textIndent: '10vh', marginBottom: '1em', marginTop: '1em' }}>
                <h1 style={{ fontSize: '2vw', textAlign: 'center' }}>{title}</h1>

                <br />
                <div>
                    {paragraph.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}
                </div>
                <p style={{ textAlign: 'right' }}>{author} <br /> {year}</p>
            </div>
            {visible && (
                <button className="btn btn-info" onClick={handleEditAbout}>Editar</button>
            )}


            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Informações</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Dias da Semana e Horas</Form.Label>
                            <Form.Control type="text" value={dayTimeSessionDescription} onChange={e => setDayTimeSessionDescription(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Salvar Alterações
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Home;
