// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import UserService from '../app/service/userService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const userService = new UserService();
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        user: null,
        loading: true,
    });

    useEffect(() => {
        const initializeAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const isValid = await userService.validate(token);
                    if (isValid.data) {
                        const userResponse = await userService.getByToken(token);
                        setAuthState({
                            isAuthenticated: true,
                            user: userResponse.data,
                            loading: false,
                        });
                    } else {
                        localStorage.removeItem('token');
                        setAuthState({ isAuthenticated: false, user: null, loading: false });
                    }
                } catch (error) {
                    localStorage.removeItem('token');
                    setAuthState({ isAuthenticated: false, user: null, loading: false });
                }
            } else {
                setAuthState({ isAuthenticated: false, user: null, loading: false });
            }
        };

        initializeAuth();
    }, []);

    const login = async (email, password) => {
        const response = await userService.login({ email, password });
        localStorage.setItem('token', response.data.token);
        const userResponse = await userService.getByToken(response.data.token);
        setAuthState({
            isAuthenticated: true,
            user: userResponse.data,
            loading: false,
        });
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuthState({ isAuthenticated: false, user: null, loading: false });
    };

    return (
        <AuthContext.Provider value={{ ...authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
