import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import MemberService from '../../app/service/memberService';
import SelectMenu from "../../components/selectMenu";
import { messageSuccess, messageError } from '../../components/toastr';
import { AuthContext } from '../../security/AuthContext';

function RegisterUser() {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const userService = new UserService();
    const memberService = useMemo(() => new MemberService(), []);
    const [userEdit, setUserEdit] = useState(JSON.parse(localStorage.getItem('user-edit')));
    const selectOption = { label: 'Selecione', value: 0 };
    const [visible, setVisible] = useState(false);
    const [member, setMember] = useState('');
    const [memberList, setMemberList] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [role, setRole] = useState(0);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');

    const validateEmail = (inputEmail) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    const handleEmailChange = (inputEmail) => {
        setEmail(inputEmail);
        if (!validateEmail(inputEmail)) {
            setEmailError('Digite um email válido');
        } else {
            setEmailError('');
        }
    };

    const handlePasswordChange = (inputPassword) => {
        setPassword(inputPassword);
        if (password2 && inputPassword !== password2) {
            setPasswordError('As senhas não coincidem');
        } else {
            setPasswordError('');
        }
    };

    const handlePassword2Change = (inputPassword2) => {
        setPassword2(inputPassword2);
        if (inputPassword2 !== password) {
            setPasswordError('As senhas não coincidem');
        } else {
            setPasswordError('');
        }
    };

    const register = async () => {
        if (password !== password2) {
            setPasswordError('As senhas não coincidem');
            messageError("Senhas não coincidem.")
            return;
        }
        if (!validateEmail(email)) {
            setEmailError('Digite um email válido');
            messageError("Email inválido. Favor inserir email válido.")
            return;
        }
        if (member === 0 && role !== 1) {
            messageError("Selecione o irmão referente à este usuário.")
            return;
        }

        try {
            const userExist = await userService.getByMemberId(member);
            let isEdit = false;

            if (userExist.data) {
                isEdit = true;
            }

            // Limpar os erros se o cadastro for bem-sucedido
            setPasswordError('');
            setEmailError('');
            if (isEdit) {
                await userService.update({
                    id: userEdit,
                    email: email,
                    password: password,
                    role: role,
                    member: member,
                    status: 1
                }).then(response => {
                    messageSuccess('Usuário atualizado com sucesso.');
                    navigate('/user-list');
                }).catch(error => {
                    messageError(error.response.data);
                });
            } else {
                await userService.new({
                    email: email,
                    password: password,
                    role: role,
                    member: member,
                    status: 1
                }).then(response => {
                    messageSuccess('Usuário cadastrado com sucesso.');
                    navigate('/user-list');
                }).catch(error => {
                    messageError(error.response.data);
                });
            }
        } catch (error) {
            messageError("Erro ao verificar membro. Tente novamente mais tarde.");
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            //Verificar se o usuário está logado
            if (isAuthenticated) {
                const accessLevel = await userService.hasAccess(user.id);
                setVisible(user.role === 1);
            } else {
                navigate('/login');
            }

            const response = await memberService.getAll();
            const listMembers = [
                selectOption,
                ...response.data.map(item => ({
                    label: item.name,
                    value: item.id
                })).sort((a, b) => a.label.localeCompare(b.label))
            ];

            setMemberList(listMembers);
            if (userEdit) {
                const userEditing = await userService.getById(userEdit);
                setMember(userEditing.data.member)
                setEmail(userEditing.data.email)
                setRole(userEditing.data.role)
            } else if (user.data.role != 1) {
                setUserEdit(user.id)
                setMember(user.member)
                setEmail(user.email)
                setPassword(user.password)
                setPassword2(user.password)
                setRole(user.role)
            }
        }

        fetchData();

    }, []);
    return (
        <div>
            <Container>
                <Card title="Cadastro de novo usuário">
                    <Container>
                        <fieldset>
                            <FormGroup label="Irmão: *" htmlFor="selectMember">
                                <br />
                                <SelectMenu
                                    className="form-control"
                                    list={memberList}
                                    value={member}
                                    onChange={(e) => setMember(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                    disabled={!visible}
                                />
                            </FormGroup>
                            <br />
                            <FormGroup
                                label="Email: *"
                                htmlFor="inputEmail"
                                error={emailError}
                            >
                                <input
                                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                    type="email"
                                    id="inputEmail"
                                    name="email"
                                    value={email}
                                    placeholder="Digite o Email"
                                    onChange={(e) => handleEmailChange(e.target.value)}
                                />
                                {emailError && (
                                    <div className="invalid-feedback">
                                        {emailError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormGroup
                                label="Nova Senha: *"
                                htmlFor="inputPassword"
                            >
                                <input
                                    className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                    type="password"
                                    id="inputPassword"
                                    name="password"
                                    value={password}
                                    placeholder="Digite a Senha"
                                    onChange={(e) => handlePasswordChange(e.target.value)}
                                />
                            </FormGroup>
                            <br />
                            <FormGroup
                                label="Confirmar Senha: *"
                                htmlFor="inputPassword2"
                                error={passwordError}
                            >
                                <input
                                    className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                    type="password"
                                    id="inputPassword2"
                                    name="password2"
                                    value={password2}
                                    placeholder="Repita a Senha"
                                    onChange={(e) => handlePassword2Change(e.target.value)}
                                />
                                {passwordError && (
                                    <div className="invalid-feedback">
                                        {passwordError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormGroup
                                label="Cargo: *"
                                htmlFor="inputCargo"
                            >
                                <br />
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="optionsRadios"
                                    id="optionsRadios1"
                                    value={role}
                                    checked={role === 0}
                                    onChange={(e) => setRole(0)}
                                    disabled={!visible}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="optionsRadios1"
                                    style={{ marginLeft: '10px' }}
                                >
                                    Irmão
                                </label>
                                <br />
                                {visible && (
                                    <>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="optionsRadios"
                                            id="optionsRadios2"
                                            value={role}
                                            checked={role === 1}
                                            onChange={(e) => setRole(1)}

                                            disabled={!visible}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="optionsRadios2"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            Administrador
                                        </label>
                                    </>
                                )}
                            </FormGroup>
                        </fieldset>
                    </Container>
                </Card>

                <button onClick={register} className="btn btn-success">
                    Cadastrar
                </button>
                {' '}
                <button onClick={e => navigate('/user-list')} className="btn btn-danger">
                    Cancelar
                </button>
            </Container >
        </div>

    );
}

export default RegisterUser;
